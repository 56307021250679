import React from "react";
// others
import { Col, Row } from "antd";
import ActionButtons from "./ActionButtons";

interface IProps {
  handleEditPress?: (arg0: string,arg1:Boolean) => void;
  booking?: any;
  label: string;
  renewal?: boolean;
  actions?: boolean;
  parentState?: (obj: object) => void;
  setRenewalFormConfirm?: any;
}

const index = (props: IProps) => {
  const setLoading = (val: boolean) => {
    props.parentState({ visible: val });
  };


  return (
    <Row gutter={24} className="bf-action-header">
      <Col span={12} className="bf-action-header_left">
        <p className="bf-action-header_left-title">Pages /</p>
        <p className="bf-action-header_left-name">
          {props?.booking?.leaseType && (
            props?.renewal == false ? "Booking Form" : props.label || "Renewal Form"
          )}
        </p>
      </Col>

      <Col span={12} className="bf-action-right">
        {(props?.actions == true && props?.booking?.leaseStatus !== 'lease_expired' && props?.booking?.leaseStatus !== "lease_terminated") && (
          <ActionButtons  handleEditPressEvent={(key:string)=>props.handleEditPress(key,true)} setLoading={setLoading} renewal={props.renewal} />
        )}
      </Col>
    </Row>
  );
};

export default index;
