import React, { useState, useEffect, useMemo } from "react";

// others
import dayjs from "dayjs";
import Parkings from "./components/Parkings";
import { configKeys } from "./components/utils";
import { Loading } from "./components/modalContent";
import { useDispatch, useSelector } from "react-redux";
import { getKycDetailsService } from "./ducks/services";
import { useParams, useLocation } from "react-router-dom";
import DashboardLayout from "../../molecules/DashboardLayout";
import { disableEditButton, disablePaymentSchedule } from "./components/utils";

import {
  DocList,
  LeaseTerms,
  RenewalTerms,
  TenantDetails,
  PaymentSchedule,
} from "./components/view";

import {
  getConfigData,
  getLandlordUsers,
  getBookingDetail,
  clearBookingForm,
  getBookingUnitDetail,
  getTemplateDocuments,
  getParkingBays,
} from "./ducks/actions";

import {
  Modal,
  Collapse,
  ActionHeader,
  BookingDetailsCard as Card,
} from "./components";
import { maintenanceService } from "../../../configs/constants";
import axios from "axios";

let initialState = {
  load: false,
  visible: false,
  kycDetails: [],
  isKyc: true,
  attachDoc: {},
  modalPayload: {
    iKey: "",
    title: "",
    open: false,
    extraText: "",

  },
};

function ViewBooking() {
  // hooks initialization
  const { state } = useLocation();
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  const typeOfRenewal = useParams();
   
  let newRenewalType = typeOfRenewal['*'];
  
  // redux states
  let { bookingDetails } = useSelector((state: any) => state.newBooking);
  let {refresh_renwal} = useSelector((state: any) => state.newBooking);

  // states
  const [{ load, isKyc, visible, modalPayload, kycDetails, attachDoc }, setState] = useState(initialState);
  
  const updateState = (state: {}) => setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    if (leaseId) dispatch(getBookingDetail(leaseId));
    dispatch(getConfigData(configKeys));
    dispatch(getTemplateDocuments());

    return () => {
      dispatch(clearBookingForm());
    };
  }, [refresh_renwal]);


  useEffect(() => {
    const unitId = state?.selectedUnit?.unitId || bookingDetails?.unitId;
    const propertyId = state?.selectedProperty?.propertyId || bookingDetails?.propertyId;

    dispatch(getLandlordUsers(propertyId));
    dispatch(getParkingBays({ propertyId: propertyId }));

    if (unitId) {
      dispatch(getBookingUnitDetail(unitId));
      getKycDetails(unitId); // for bug:
    }
    if (
      Object.keys(bookingDetails).length > 0 &&
     (bookingDetails?.leaseStatus === "active" || bookingDetails?.leaseStatus === "lease_expired")
      && state?.renewal
      && !bookingDetails?.renewalStatus
    ) {
      console.log(bookingDetails,'bookingDetails')
      const leaseTerms = {
        ...bookingDetails.leaseTerms,
        ...{
          leaseStartDate: dayjs(bookingDetails.leaseTerms.leaseEndDate)
            .add(1, "day")
            .toISOString(),
          leaseEndDate: dayjs(bookingDetails.leaseTerms.leaseEndDate)
            .add(1, "year")
            .toISOString(),
        },
      };

      bookingDetails["leaseTerms"] = {
        ...bookingDetails.leaseTerms,
        ...leaseTerms,
      };
    }
  }, [bookingDetails]);

  //  for user story 8455 

  const[renewalFormConform,setRenewalFormConfirm] = useState<Boolean>(false);
 
  const handleEditPress = (key: string,renewalConform = null) => {
    let payload: any = { ...modalPayload };
    
    setRenewalFormConfirm(renewalConform);
   
    switch (key) {
      case "1":
        payload = {
          iKey: "1",
          title: "Tenant Details",
          extraText: "Enter an email address to look for an existing tenant or add a new one.",
          kycDetails, //added for bug: 7498,
        };
        break;
      case "2":
        payload = {
          iKey: "2",
          extraText: "",
          title: "Lease Terms",
        };
        break;
      case "3":
        payload = {
          iKey: "3",
          extraText: "",
          title: "Edit Payment Schedule",
        };
        break;
      case "4":
        payload = {
          iKey: "4",
          width: "70%",
          extraText: "",
          title: state?.renewal ? "Confirm Renewal Terms" : "Create Renewal Terms",
        };
        break;
      case "5":
        payload = {
          iKey: "5",
          extraText: "",
          title: "Add Payment",
        };
        break;
      case "6":
        payload = {
          iKey: "6",
          extraText: "",
          width: "450px",
          title: "Request Documents",
        };
        break;
      case "7":
        payload = {
          iKey: "7",
          extraText: "",
          width: "80%",
          className: "generate-modal",
          title: "Generate Document from Template",
        };
        break;
      case "8":
        payload = {
          iKey: "8",
          width: "80%",
          title: "Change Payment Plan",
          extraText: ""
          //   "You can add a new payment plan, edit, or disable an existing payment plan. If you add or edit a payment plan, you\nneed to send an approval request.",
        };
        break;
      case "11":
        payload = {
          iKey: "11",
          width: "80%",
          extraText: "",
          title: "Change History",
        };
        break;
      case "15":
        payload = {
          iKey: "15",
          width: "600px",
          extraText: "",
          title: "Attach Docs",
          attachDoc,
        };
        break;
    }

    updateState({ modalPayload: { ...payload, ...{ open: true } } });
  };

  const getKycDetails = async (unitId: string) => {
    try {
      const response = await getKycDetailsService(unitId);
      if (response?.length > 0)
        updateState({ isKyc: false, kycDetails: response });
      else updateState({ isKyc: false, kycDetails: [] });
    } catch (error) {
      updateState({ isKyc: false, kycDetails: [] });
    }
  };

  const disable = disableEditButton(bookingDetails, state?.renewal);

  const disableDuringRenewal = state?.renewal || isKyc || disable;

  const disableParkings = bookingDetails?.leaseStatus == "sent_for_lease_approval" ? true : bookingDetails?.leaseStatus == "sent_for_booking_approval" ? true : false;

  const[gracePeriodDays,setGracePriodDays] = useState(null);
  
  const getOrgByID = useMemo(() => async () => {
    if (!bookingDetails?.orgId) return; // Prevent unnecessary calls
  
    try {
      const data = await axios.get(`${maintenanceService}/organization/${bookingDetails?.orgId}`);
      setGracePriodDays(data?.data?.result?.dayForPaynowbtnBeforeDueDate);
    } catch (e) {
      console.log("Err", e);
    }
  }, [bookingDetails?.orgId]); // Memoize based on orgId change

  useEffect(()=>{
   ( bookingDetails && bookingDetails?.isAutoPayActive) && getOrgByID();
  },[bookingDetails?.orgId]);

  return (
    <DashboardLayout load={load} className="bf-main-content">
      <Loading visible={visible} />

      <ActionHeader
        label=""
        renewal={state?.renewal || bookingDetails?.leaseType === "renewal"}
        booking={bookingDetails}
        parentState={updateState}
        actions={true}
        handleEditPress={handleEditPress}
    
      />

      <Card
        booking={bookingDetails}
        renewal={state?.renewal || bookingDetails?.leaseType === "renewal"}
        stateRenewal={state?.renewal}
        handleChangeHistory={handleEditPress}
      />

      <Collapse
        key="1"
        iKey="1"
        header="Tenant Details"
        modalKey={modalPayload.iKey}
        disable={disableDuringRenewal}
        handleEditPress={handleEditPress}
      >
        <TenantDetails booking={bookingDetails} kycDetails={kycDetails} />
        {/* //added for bug: 7498 */}
      </Collapse>

      <Collapse
        key="2"
        iKey="2"
        header="Lease Terms"
        modalKey={modalPayload.iKey}
        disable={disableDuringRenewal}
        handleEditPress={handleEditPress}
      >
      <LeaseTerms 
        contractNumber ={ bookingDetails?.ejari?.contractNumber}
        booking={bookingDetails} 
        renewal={state?.renewal || 
        bookingDetails?.leaseType === "renewal"}
         />
      </Collapse>

      <Collapse
        key="6"
        iKey="6"
        disable={disable}
        header="Parkings"
        modalKey={modalPayload.iKey}
      >
        <Parkings data={bookingDetails?.parkings} update={true} disable={disableParkings} />
      </Collapse>

      {(state?.renewal || bookingDetails?.leaseType === "renewal" || newRenewalType === "renewalTorenewal") && (
        <Collapse
          key="4"
          iKey="4"
          disable={(bookingDetails?.renewalStatus === 'sent_for_approval' ||  bookingDetails?.renewalStatus === "renewal_accepted")}
          header="Renewal Terms"
          modalKey={modalPayload.iKey}
          handleEditPress={handleEditPress}
        >
          <RenewalTerms newRenewalType={newRenewalType} booking={bookingDetails} />
        </Collapse>
      )}
      <Collapse
        key="3"
        iKey="3"
        header="Payment Schedule"
        modalKey={modalPayload.iKey}
        handleEditPress={handleEditPress}
        disable={disablePaymentSchedule(bookingDetails) }
        renewal={
          state?.renewal ||
          bookingDetails?.propertyDetails?.usagesType == "Commercial"
        } // to hide change plan button for commercial
      >
        <PaymentSchedule gracePeriodDays={gracePeriodDays} newRenewalType={newRenewalType} renewal={state?.renewal} booking={bookingDetails} />
      </Collapse>

      <Collapse key="5" iKey="5" header="Documents" disable={disable}>
        <DocList
          booking={bookingDetails}
          handleGenerate={handleEditPress}
          parentState={updateState}
          disable={disable}
        />
      </Collapse>

      <Modal
        className="bd-modal"
        renewal={state?.renewal}
        updateState={updateState}
        modalPayload={modalPayload}
        setRenewalFormConfirm={setRenewalFormConfirm}
        renewalFormConform={renewalFormConform}
      />
    </DashboardLayout>
  );
}

export default ViewBooking;
