import React, { useState } from "react";

// others
import { ItemCard } from "../components";
import { Col, Row, Spin, Space, Tag, Typography, message } from "antd";
import { useParams } from "react-router-dom";
import {
  apiDateFormat,
  numberWithCommas,
  leaseExecutiveName,
  ApprovalHeader,
  CurrencyExchangeIcon
} from "./utils";

import { Popup, PopupConfirm } from "../../../atoms/Popup";

import RejectPopup from "../../LeasingFormNew/components/RejectionPopup";

import { formatString } from "../../../../utils/formatString";
import {
  Bed,
  Measure,
  History,
  Apartment,
  Residential,
} from "../../../../assets/svg";
import { statusLabel } from "../../../../utils/media";
import { getBookingDetail, updateBooking } from "../ducks/actions";
import { useDispatch } from "react-redux";

interface IProps {
  booking: any;
  renewal?: boolean;
  handleChangeHistory?: (key: string) => void;
  stateRenewal?:boolean

}

var _ = require("lodash");



const { Title } = Typography;

const index = (props: IProps) => {
  // variables
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();
  const typeOfRenewal = useParams();
  let newRenewalType = typeOfRenewal['*'];

  const isBooking = props?.booking && Object.keys(props?.booking)?.length > 0;

  const rowStyle = { marginLeft: 0, marginRight: 0 };
  const rowStyleWithTopmargin = { ...rowStyle, ...{ marginTop: "16px" } };
  const isLeaseManager = Object.keys(props.booking?.leaseManager || {}).length > 0;

  const renewal_accepted = props?.booking?.leaseStatusHistory?.find((status) => status?.status === 'renewal_accepted' || status?.status === 'renewal_confirmed');
  
  const diffLeaseStatus = () => {


    if(props?.stateRenewal || props?.booking?.leaseType === "renewal"){

      // if (props.booking?.leaseStatus === 'active' && props.booking?.renewalStatus === "renewal_accepted")
      // //   return 'active_renewing';
      // else if (props.booking?.leaseStatus === "active" && props.booking?.renewalStatus === "renewal_declined")
      //   return 'active_vacating';
      
      if(props.booking?.leaseStatus === "awaiting_documents" && props.booking?.renewalStatus === "renewal_declined")
        return props.booking?.renewalStatus;
      else if (props?.booking?.leaseType === "renewal" && !renewal_accepted) {
        return props.booking?.renewalStatus;
      }
      else if (props.booking?.leaseStatus === 'active' && props.booking?.renewalStatus === "active" && newRenewalType === "renewalTorenewal")
        //  when creating new renewal from exisiting renewal
        return 'To Review';
      else if(props.booking?.leaseStatus === 'awaiting_renewal_fee' &&  props.booking?.renewalStatus === "renewal_declined")  
        return 'Renewal Declined';
      else
        return props.booking?.leaseStatus;
    }else{
      return props.booking?.leaseStatus;
    }
    
  }

  const [modalvisible, setModalvisible] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(0);

  const label = () => {
    if (props?.renewal || props?.booking?.leaseType === "renewal") return "Renewal Id";
    else return "Booking Id";
  }

  const moveOutSummeryApproval = () => {

    let body = {
      "settlementApproval": "approved",
      "onlyBooking": true
    };
    PopupConfirm({
      title: `Are you sure you want to approve move-out summary?`,
      content: "",
      onOk: async () => {
        try {

          const response: any = await dispatch(
            updateBooking({ leaseId, body: body })
          );;

          if (response?.data?.code === 200) {
            message.success(response?.data?.message);
            dispatch(getBookingDetail(leaseId + `?moveout=yes`));
          }
        } catch (error) {
          message.error(
            error.response?.data?.error || error.response?.data?.message
          );
        }
      },
      okText: "Confirm",
    });
  }

  const rejectMoveOutSummery = async (data) => {


    let body = {
      "settlementApproval": "rejected",
      "rejectionReason": data?.rejectReason ?? "",
      "onlyBooking": true
    };

    try {

      const response: any = await dispatch(
        updateBooking({ leaseId, body: body })
      );

      if (response?.data?.code === 200) {
        setModalvisible(false);
        dispatch(getBookingDetail(leaseId + `?moveout=yes`));
        message.success(response?.data?.message);
      }
    } catch (error) {
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }


  }

  const popup = [

    {
      title: (
        <Title level={3} className="mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
      content: (
        <RejectPopup
          onSubmit={rejectMoveOutSummery}
          onClose={() => setModalvisible(false)}
        />
      ),
    },
  ];

  const popupProps = {
    closable: true,
    visibility: modalvisible,
    title: popup[modalTrigger]?.title,
    content: popup[modalTrigger].content,
    width: popup[modalTrigger].width,
    onCancel: () => setModalvisible(false),
  };


  const autoPayDisable = () => {

    let body = {
      "disableAutoPay": true,
      "onlyBooking": true
    };
    PopupConfirm({
      title: `Are you sure you want to cancel autopay?`,
      content: "",
      onOk: async () => {
        try {

          const response: any = await dispatch(
            updateBooking({ leaseId, body: body })
          );;

          if (response?.data?.code === 200) {
            message.success(response?.data?.message);
            dispatch(getBookingDetail(leaseId));
          }
        } catch (error) {
          message.error(
            error.response?.data?.error || error.response?.data?.message
          );
        }
      },
      okText: "Confirm",
    });
  }

  return (

    <Col span={24} className="cc-main view-booking">
      {!isBooking && (
        <Row gutter={24} className="loading">
          <Spin size="large" />
        </Row>
      )}


      {isBooking && (
        <>
          <Row gutter={12} style={rowStyle} className="row">
            <p className="ft-main-label">
              {`${props.booking?.propertyDetails?.unitNumber} ${props.booking?.propertyDetails?.propertyName} | ${props.booking?.propertyDetails?.unitCode}${props?.booking?.bookingId ? ` | ${label()} - ${props.booking?.bookingId}` : ``}`}
            </p>
            <div>
              <p className="date">
                {apiDateFormat(
                  props?.booking?.leaseTerms?.leaseStartDate,
                  "MMM-DD-YYYY"
                )}
                {" - "}
                {apiDateFormat(
                  props?.booking?.leaseTerms?.leaseEndDate,
                  "MMM-DD-YYYY"
                )}
              </p>
              {/* according to story 8455 if vacating date have */}
              {
                props?.booking?.vacatingDate &&

                <p className="date">
                  ( Vacating {apiDateFormat(props?.booking?.vacatingDate, "MMM-DD-YYYY")} )
                </p>
              }

            </div>

          </Row>

          <Row gutter={12} style={rowStyleWithTopmargin} className="row">
            <Row>
              <ItemCard
                label={props?.booking?.propertyDetails?.usagesType}
                icon={Residential}
              />
              <ItemCard
                label={props?.booking?.propertyDetails?.unitType}
                icon={Apartment}
              />
              {props?.booking?.propertyDetails?.unitSubType?.includes("BR") && (
                <ItemCard
                  label={`${props?.booking?.propertyDetails?.unitSubType?.split("BR")[0]
                    } Bedroom`}
                  icon={Bed}
                />
              )}
              <ItemCard
                label={`${props?.booking?.propertyDetails?.areaSqft} sqft`}
                icon={Measure}
              />
            </Row>

            {props?.booking?.bookingTerms?.annualBaseRent && (
              <Row gutter={12} className="row">
                <p className="amount">{`AED ${numberWithCommas(
                  props?.booking?.bookingTerms?.renewalAmount ||
                  props?.booking?.bookingTerms?.annualBaseRent
                )} / `}</p>
                <p className="year">Year</p>
              </Row>
            )}
          </Row>

          <Col span={24} className="ft-divider" />

          <Row gutter={16} style={rowStyle} className="row">
            <Row style={{ alignItems: "center" }}>
              {props.booking?.leaseManager?.name && (
                <>
                  <Row className="user">
                    <p className="user-label">
                      {leaseExecutiveName(props.booking?.leaseManager?.name)}
                    </p>
                  </Row>

                  <p className="user-name">
                    {props.booking?.leaseManager?.name}
                  </p>
                  <p className="user-executive">{`( Lease Executive )`}</p>
                </>
              )}
              {props.booking?.adminManager?.name && (
                <>
                  <Row className="user">
                    <p className="user-label">
                      {leaseExecutiveName(props.booking?.adminManager?.name)}
                    </p>
                  </Row>

                  <p className="user-name">
                    {props.booking?.adminManager?.name}
                  </p>
                  <p className="user-executive">{`( Property Admin )`}</p>
                </>
              )}


              <Space

                className="history-container"
                onClick={() => props.handleChangeHistory("11")}
                style={{
                  letterSpacing: "1.2px",
                  marginLeft: !isLeaseManager ? 0 : "48px",
                }}
              >
                <History
                  width="18px"
                  height="18px"
                  style={{ marginTop: "4px", marginRight: "4px" }}
                />

                <p className="history-label">Change History</p>

                {/* <CurrencyExchangeIcon/> */}
              </Space>
              {
                props?.booking?.isAutoPayActive &&
                <Space

                  className="history-container"
                  onClick={() => autoPayDisable()}
                  style={{
                    letterSpacing: "1.2px",
                    marginLeft: "10px",
                  }}
                >

                  <CurrencyExchangeIcon
                    width="28px"
                    height="28px"
                  />

                  <p className="history-label">Cancel Autopay</p>

                </Space>
              }
            </Row>

            <Row gutter={8} className="status-container">
              <p className="status-label">
                {
                 (props?.stateRenewal  || props?.booking?.leaseType === "renewal") 
                  ?

                  formatString(statusLabel(props.renewal && !props.booking?.renewalStatus ? "to_review" : diffLeaseStatus(), props?.booking?.leaseStatusHistory))
                  ||
                  formatString(statusLabel(diffLeaseStatus(), props?.booking?.leaseStatusHistory))
                  :
                  formatString(props.booking?.leaseStatus)
                }
              </p>
            </Row>
          </Row>

          {
            (props?.booking?.settlementApproval && props?.booking?.settlementApproval === 'pending') &&
            <Tag className="fm-status-header" color="cyan" style={{ marginTop: '10px' }}>
              <div>

                <ApprovalHeader
                  title={'Approval required for move-out summary'}
                  approve={moveOutSummeryApproval}
                  reject={() => { setModalTrigger(0); setModalvisible(true) }}
                />

              </div>
            </Tag>
          }

        </>
      )}
      <Popup destroyOnClose={true} {...popupProps} />
    </Col>
  );
};

export default index;
