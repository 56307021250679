import React, { useState, useEffect, ReactNode } from "react";

// others
import { Col, Collapse } from "antd";
import { CollapseIcons } from "../components";

interface IProps {
  iKey: string;
  header: string;
  modalKey?: string;
  disable?: boolean;
  renewal?: boolean;
  children: ReactNode;
  handleEditPress?: (key: string) => void;
}

let initialState = {
  activeKey: "",
};

const { Panel } = Collapse;

const index = (props: IProps) => {
  // states
  const [{ activeKey }, setState] = useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    if (props?.modalKey) updateState({ activeKey: props?.modalKey });
  }, [props?.modalKey]);

  return (
    <Collapse
      defaultActiveKey={['1', '2', '3', '4', '5', '6','17']}
      // accordion
      bordered={false}
      // activeKey={props?.modalKey || activeKey}
      onChange={(e) => updateState({ activeKey: e })}
      expandIcon={({ isActive }) => (
        <CollapseIcons
          iKey={props.iKey}
          isActive={isActive}
          disable={props.disable}
          renewal={props.renewal}
          handleEditPress={props.handleEditPress}
        />
      )}
    >
      <Panel key={props.iKey} header={props.header}>
        <Col span={24} className="ft-divider" />
        {props.children}
      </Panel>
    </Collapse>
  );
};

export default index;
