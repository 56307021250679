import React,{useEffect, useMemo,useState} from "react";

// others
import { Col, Row, Spin } from "antd";
import Grid from "../../../../atoms/Grid";
import { columns, filterDescriptionLabel } from "../utils";

interface IProps {
  booking: any;
  renewal?: boolean;
  newRenewalType?:any,
  gracePeriodDays?:any
}

const index = (props: IProps) => {
  // variables
  const isBooking = props?.booking && Object.keys(props.booking).length > 0;
 

  return (
    <Col span={24} className="view-booking">
      {!isBooking && (
        <Row gutter={24} className="loading">
          <Spin size="large" />
        </Row>
      )}

      {(isBooking) && (
        <Grid
          columns={columns}
          height={'315px'}
          data={filterDescriptionLabel(props.booking?.paymentSchedule,true,props.booking,props.renewal,props?.newRenewalType,props?.gracePeriodDays)}
        />
      )}
    </Col>
  );
};

export default React.memo(index);
