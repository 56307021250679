import { Card, Col, Row, Typography, message, Pagination } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { dateByformat } from "../../../../../../utils/dateHelper";
import Grid from "../../../../../atoms/Grid";
import { statusLabel } from "../../../../../../utils/media";
import { ArrowRightOutlined } from "@ant-design/icons";
import Permissions from "../../../../../../utils/permissions";
import { MODULES } from "../../../../../../configs/modules";
import { READ } from "../../../../../../configs/operations";
import { checkScreenAccess } from "../../../../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import {
  getTicketsForTenant
} from "../../../../Maintenance/Tickets/ducks/actions";
import { getPropertyUnits } from "../../../../Properties/PropertyDetail/ducks/actions";

import { Popup } from "../../../../../atoms/Popup";
import AddTicket from "../../../../Maintenance/Tickets/components/AddTicket";
import ViewTicket from "../../../../Maintenance/Tickets/components/ViewTicket";
import AssignTechnician from "../../../../Maintenance/Tickets/components/AssignTechnician";
import ReAssignTechnician from "../../../../Maintenance/Tickets/components/ReAssignTechnician";
import ExportTicket from "../../../../Maintenance/Tickets/components/ExportTicket";
import ActivityLog from "../../../../Maintenance/Tickets/components/ViewTicket/activityLog";
import RejectTicket from "../../../../Maintenance/Tickets/components/ViewTicket/rejectTicket";
import ViewTenantDetail from "../../../../Maintenance/Tickets/components/ViewTicket/ViewTenantDetail";
import { getPresignedImage } from "../../../../../../utils/media";
import SectionHeader from "../../../../../molecules/SectionHeader";
import { FilterOutlined } from "@ant-design/icons";
import Loader from "../../../../../modules/Loader.tsx";

var _ = require("lodash");

const { Text, Title } = Typography;

interface TicketParams {
  propertyId?: String;
  unitId?: String;
  ticketStatus?: String;
  tenantId?: String
}

export default ({ tenantData }) => {
  const [ticketsForTenant, setTicketsForTenant] = useState([]);
  const [ticketsForColumn, setTicketsForColumn] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [propertyId,setPropertyId] = useState('');
  const[unitId,setUnitID] = useState('')
  const[ticketStatus,setTicketStatus] = useState('all')
  const [trigger, setTrigger] = useState(0);
  const [visible, setVisible] = useState(false);
  const [recurringTicket, setRecurringTicket] = useState();
  const [activityLogTicketData, setActivityLogTicketData] = useState();
  const [rejectTicketData, setRejectTicketData] = useState();
  const [tenantDetailData, setTenantDetailData] = useState();
  const [selectedTicket, setSelectedTickets] = useState();
  const [rejectTicketSubmit, setRejectTicketSubmit] = useState();
  const [unitLoading, setUnitLoading] = useState(false);
    const[isLoading,setIsLoading] = useState(true);
  
  const dispatch: any = useDispatch();
  const propertiesList = useSelector((state: any) => state.dashboard.propertiesDrop); 
  
  const properties = useSelector((state: any) => state.tickets.propertyname)
  let units = useSelector((state: any) => state.propertyDetails.unitsDropdown);
  const usertype = JSON.parse(window.localStorage.getItem("login-response"))?.usertype
  const defaultPropertyOpt = {label: "All Properties",value: ""}
  const defaultUnitOpt = {label: unitLoading ? 'Loading...' : "All Units",value: ""}
  const userScreenAccess: any = useSelector((state: any) => state?.userScreens?.userScreenAccess);

  let allUnits = [];
  let allProperties = [];
  let allStatuses = [ {label: 'All',value: "all"}, {label: 'Open/In Progress',value: "openInProgress"},{value: 'completed', label: 'Closed'}];
  if(!usertype.includes("serviceProvider")){
    allProperties = propertiesList ? propertiesList :[];
    allUnits = units ? units : [];
  }else{
    allUnits = units ? units : [];
    properties?.forEach((item)=>{
      allProperties.push({
            value:item?.propertyId,
            label:item?.buildingName,
            buName:item?.businessUnitName	
      })
    })
  }

  useEffect(() => {
    dispatch(getPropertyUnits(false,false,true,setUnitLoading));
  }, []);

  const fetchTicketsForTenant = async () => {
    setIsLoading(true);
    setTicketsForTenant([]);
    setPage(1);
  
    const param: TicketParams = {};
    param.tenantId = tenantData?.userDetail?.userId;
    if (propertyId) param.propertyId = propertyId;
    if (unitId) param.unitId = unitId;
    if (ticketStatus) param.ticketStatus = ticketStatus;

    const tickets  = await getTicketsForTenant(1, param);
    setTicketsForTenant(tickets.data)
    setTotalCount(tickets.totalCount)
    setIsLoading(false)
  };

  useEffect(() => {
    fetchTicketsForTenant();
  }, [propertyId, unitId, ticketStatus]);

  const getTicketStatus = (status: String) => {
    return status?.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  useEffect(() => {
    let temp = [];
    if(ticketsForTenant && ticketsForTenant.length > 0) {
      ticketsForTenant.map((ticket) => {
        temp.push({
          'dateOfSchedule': ticket.preferredDate,
          'unitNumber': ticket.unitCode,
          'property': ticket.propertyName,
          'category': ticket.category.name,
          'subcategory': convertToNormalCase(ticket.title),
          'status':  getTicketStatus(ticket.ticketStatus),
          'rating': ticket?.ratingAndReview?.rating || '',
          'ticket': ticket
        })
      })
    }
    setTicketsForColumn(temp)
  }, [ticketsForTenant]);

  const convertToNormalCase = (input = '') => {
    // Check if the input is in camel case
    if (input?.indexOf('_') === -1 && input !== input?.toLowerCase()) {
      return input?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/\b\w/g, char => char.toUpperCase());
    }

    // Check if the input is in snake case
    if (input?.indexOf('_') !== -1) {
      return input?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    return input?.charAt(0).toUpperCase() + input?.slice(1); // Already in normal case
  }

  const columns = [
    {
      headerName: "Date",
      field: "dateOfSchedule",
      maxWidth: 350,
      cellRendererFramework: (item) => dateByformat(item.data.dateOfSchedule, "DD-MM-YYYY"),
    },
    {
      headerName: "Unit No",
      field: "unitNumber",
    },
    {
      headerName: "Property",
      field: "property",
      minWidth: 250,
      maxWidth: 320,
    },
    {
      headerName: "Category",
      field: "category",
    },
    {
      headerName: "Subcategory",
      field: "subcategory",
    },
    {
      headerName: "Status",
      field: "status",
      // cellRendererFramework: (item) => dateByformat(item.data.leaseStartDate, "DD-MM-YYYY"),
    },
    {
      headerName: "Rating",
      field: "rating",
      // cellRendererFramework: (item) => dateByformat(item.data.leaseEndDate, "DD-MM-YYYY"),
    },
    {
      headerName: "Action",
      cellRendererFramework: (item) => renderViewTicketButton(item),
    },
  ];

  const onSubmit = () => {
    fetchTicketsForTenant();
    setVisible(false);
  };

  function assignTechnician() {
    setTrigger(2);
  }

  function originalTicket(ticket) {
    callImages(ticket, true);
  }
  function activityLogTicket(ticket) {
    callImages(ticket, "activity");
  }

  function rejectTicketTicket(ticket) {
    callImages(ticket, "rejectTicket");
  }

  function viewTeanantDetail(ticket) {
    callImages(ticket, "tenantDetail");
  }

  function onAssign() {
    setTrigger(1);
  }

  const onClickExport = async () => {
    alert('export clicked');
  };

  function addTicket() {
    setVisible(true);
    setTrigger(0);
  }

  function downloadAction() {
    setTrigger(8);
    setVisible(true);
  }

  const callImages = async (item, recuring, type = "") => {
    if (
      recuring !== "activity" &&
      recuring !== "rejectTicket" &&
      recuring !== "tenantDetail"
    ) {
      let totalImages = [];
      let totalVideos = [];
      if (item.documents && item.documents.length > 0) {
        item.documents.map((x) => {
          if (x?.documentId && !x?.type.includes("mp4")) {
            totalImages.push(x.documentId);
          } else {
            totalVideos.push(x.documentId);
          }
        });
        const images = await getMedia(totalImages);
        const videos = await getMedia(totalVideos);
        item.totalImageURL = images;
        item.totalVideoUrls = videos;
      }

      let techtotalImages = [];
      let techtotalVideos = [];
      if (item?.technicianDocuments && item?.technicianDocuments?.length > 0) {
        item.technicianDocuments.map((x) => {
          if (x?.documentId &&  !x?.type.includes("mp4")) {
            techtotalImages.push(x.documentId);
          } else {
            techtotalVideos.push(x.documentId);
          }
        });
        const images = await getMedia(techtotalImages);
        const videos = await getMedia(techtotalVideos);
        item.techtotalImageURL = images;
        item.techtotalVideoUrls = videos;
      }
      
    }
    if (
      recuring &&
      recuring !== "activity" &&
      recuring !== "rejectTicket" &&
      recuring !== "tenantDetail"
    ) {
      setRecurringTicket({ ...item, ...{ ticketStatus: type } });
      setTrigger(3);
    } else if (recuring == "activity") {
      setActivityLogTicketData({ ...item, ...{ ticketStatus: type } });
      setTrigger(4);
    } else if (recuring == "rejectTicket") {
      setRejectTicketData({ ...item, ...{ ticketStatus: type } });
      setTrigger(5);
    } else if (recuring == "tenantDetail") {
      setTenantDetailData({ ...item, ...{ ticketStatus: type } });
      setTrigger(6);
    } else {
      setTrigger(1);
      setVisible(true);
      setSelectedTickets({ ...item, ...{ ticketStatus: type } });
    }
  };

  const getMedia = async (totalImages) => {
    if (totalImages?.length > 0) {
      const result = await getPresignedImage(totalImages);
      if (result && result.length > 0) {
      } else {
        message.error("Something wrong with Media");
      }
      return result;
    }
    return [];
  };

  const popup = [
    {
      closable: true,
      title: (
        <Title level={3} className="mb-0">
          Add a Ticket
        </Title>
      ),
      content: (
        <AddTicket
          onSubmit={() => onSubmit()}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 680,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={selectedTicket}
          onRecurring={(e) => originalTicket(e)}
          onActivityLog={(e) => activityLogTicket(e)}
          onRejectTicket={(e) => rejectTicketTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
          onSubmit={onSubmit}
        />
      ),
      width: 920,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign Technician
        </Title>
      ),
      content: (
        <AssignTechnician
          ticket={selectedTicket}
          onBack={() => setTrigger(1)}
          onAssign={onAssign}
        />
      ),
      width: 650,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={recurringTicket}
          onActivityLog={(e) => activityLogTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onBack={() => {
            setTrigger(1);
            setRecurringTicket(null);
          }}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
          onSubmitTicket={onSubmit}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ActivityLog
          data={activityLogTicketData}
          onBack={() => {
            if (recurringTicket) {
              setTrigger(3);
            } else {
              setTrigger(1);
            }
            setActivityLogTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <RejectTicket
          data={rejectTicketData}
          setRejectTicketSubmit={setRejectTicketSubmit}
          onBack={() => {
            setTrigger(1);
            setRejectTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ViewTenantDetail
          data={tenantDetailData}
          onBack={() => {
            if (recurringTicket) {
              setTrigger(3);
            } else {
              setTrigger(1);
            }
            setTenantDetailData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Modify Ticket
        </Title>
      ),
      content: (
        <ReAssignTechnician
          ticket={selectedTicket}
          onBack={() => setVisible(false)}
          onAssign={onAssign}
          onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Export Tickets
        </Title>
      ),
      content: (
        <ExportTicket
          onSubmit={() => onSubmit()}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: trigger == 0 ? true : false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function renderViewTicketButton(item) {
    if(!Permissions.isAllowed(MODULES.PROPERTIES, [READ]) || !checkScreenAccess("tickets", false, userScreenAccess)) {
      return
    }
    return (
      <div onClick={() => {callImages(item?.data?.ticket, false, 'new')}}>
        <a className={`f-16`} target="_blank" style={{ textDecoration: 'underline' }}>
          View Ticket
        </a>
        <ArrowRightOutlined style={{ marginLeft: 5, cursor: 'pointer', paddingTop: 2, color: 'grey' }} />
      </div>
    );
  }

  const searchTrigger = (e) => {
  if(e?.value.includes('propertyId') || e?.value === ''){
      setPropertyId(e?.value);
      setUnitID(null)
      dispatch(getPropertyUnits( e?.value === '' ? false : e?.value,false,true,setUnitLoading));
    }else if(e?.value.includes('unitId') || e?.value === ''){
      setUnitID(e?.value);
    } else {
      setTicketStatus(e?.value);
    }
  };

  const filterOptions = [
    {
      name: "property",
      label: "Property",
      search: true,
      options: [defaultPropertyOpt,...allProperties],
    },
    {
      name: "unit",
      label: "Unit",
      search: true,
      options: [defaultUnitOpt,...allUnits],
    },
    {
      name: "status",
      label: "Status",
      initialValue: 'all',
      search: false,
      options: allStatuses,
    },
  ];

  const loadPaginatedTickets = async (selectedCount) => {
    setIsLoading(true);

    const param: TicketParams = {};
    param.tenantId = tenantData?.userDetail?.userId;
    if (propertyId) param.propertyId = propertyId;
    if (unitId) param.unitId = unitId;
    if (ticketStatus) param.ticketStatus = ticketStatus;
    setPage(selectedCount);

    const tickets  = await getTicketsForTenant(selectedCount, param);
    setTicketsForTenant(tickets.data)
    setTotalCount(tickets.totalCount)
    setIsLoading(false)
  };

  return (
    <>
      <Col span={24}>
        <SectionHeader
          heading="Tickets"
          noBorder={true}
          total={totalCount}
          viewOption={filterOptions}
          justifiedItems={true}
          justifyEnd={true}
          bottom={true}
          right={true}
          handleView={searchTrigger}
          onChange={(e) => {searchTrigger(e)}}
        />
        </Col>
    {isLoading ? <Loader/> :
      <Card bordered={false}>
        <Row gutter={[60, 0]}>
          <Col span={24}>
            <Col span={24}>
            <Pagination
                className="ag-property-text-pagination"
                total={totalCount}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Tickets`
                }
                showSizeChanger={false}
                defaultPageSize={10}
                defaultCurrent={1}
                current={page}
                onChange={(e) => loadPaginatedTickets(e)}
              />
            </Col>
            
            <Grid
              data={ticketsForColumn}
              columns={columns}
              pagination={false}
              noRowlabel="Lease"
              fullPage={true}
            />
          </Col>
        </Row>
      </Card>
    }
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};
