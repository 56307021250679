import React, { useState, useEffect ,useLayoutEffect} from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Form,
  Space,
  Select,
  Typography,
  MenuProps,
  message,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getTenants, sendInvite , activeDeActiveUser} from "./ducks/actions";
import { useNavigate } from "react-router-dom";
import { FilterOutlined, MailOutlined } from "@ant-design/icons";
import { PopupConfirm } from "../../atoms/Popup";
import Title from "antd/es/typography/Title";
import { Popup } from "../../atoms/Popup";
import AddLease from "./components/AddLease/AddLease";
import Modal from "./components/Modal/Modal";
import AddBilling from "./components/AddBilling/AddBilling";
import AddPayment from "./components/AddPayment/AddPayment";
import { getPropertiesDrop } from "../Properties/ducks/actions";
import { emptyPropertyUnits, getPropertyUnits } from "../Properties/PropertyDetail/ducks/actions";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterTenant } from "../App/SearchFilters/ducks/actions";
import LoaderTsx from "../Loader.tsx";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 50;

const searchKeys = [
  { value: "name", label: "Name", default: true },
  { value: "phone", label: "Phone" },
  { value: "email", label: "Email" },
  { value: "emiratesId", label: "Emirates ID" },
  { value: "passport", label: "Passport No" },
  { value: "tradeLicense", label: "Trade License No" }
  ];

export default React.memo( (props) => {

  const dispatch: any = useDispatch();
  const id = location.pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tenantApi = useSelector((state: any) => state.tenants.tenantList);
  const properties = useSelector((state: any) => state.dashboard.propertiesDrop);
  const units = useSelector((state: any) => state.propertyDetails.unitsList);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [updateValue, setUpdateValue] = useState({});

  const userInformation = JSON.parse(localStorage.getItem('login-response'));

  const Filter = useSelector((state: any) => state.filters.tenantsData);

  const { searchHistory, addToSearchHistory } = useSearchHistory(setFilterTenant);
  const [searchBy, setSearchBy] = useState(Object.keys(Filter)[0] ?? "name")
  const [search, setSearch] = useState(Filter[Object.keys(Filter)[0]] ?? "");

  const [visible, setVisible] = useState(false);
  const [singleInvite, setSingleInvite] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deselectAll, setDeselectAll] = useState(false);
  const [tenantStatus,setTenantStatus] = useState(null);
  const [tenantLoading,setTenantLoading] = useState(null);
  const [screenSize, setScreenSize] = React?.useState('lg');
  const [setSelect, setSelectValue] = React?.useState(false);
  
  const filterStatus = [
    {
     label:'All',
     value:'all'
    },
    {
     label:'Registered',
     value:'registered'
    },
    {
     label:'Not registered',
     value:'not_registered'
    },
    {
     label:'Inactive/Deleted',
     value:'inactive_deleted'
    },
  ];


  const MenuItems: MenuProps["items"] = [
    {
      label: "Active Tenancy",
      key: 0,
    },
   
    {
      label: "Payment Schedule",
      key: 2,
    },
  ];

  const [propertyOptions, setPropertyOptions] = useState([
    {
      name: "property",
      label: "property",
      options: [],
      search: true
    },
    {
      name: "unit",
      label: "Unit",
      options: [],
      search: true
    },
    {
      name: "status",
      label: "Status",
      options: filterStatus,
      search: true
    },
  ]);

  
 
  useEffect(() => {
    dispatch(getPropertiesDrop());
    if (properties.length === 0) {
      // dispatch(getPropertiesDrop());
    } else {
      setPropertyOptions([
        {
          name: "property",
          label: "property",
          options: properties,
          search: true
        },
        {
          name: "unit",
          label: "Unit",
          options: [],
          search: true
        },
        {
          name: "status",
          label: "Status",
          options: filterStatus,
          search: true
        },
      ]);
    }
  }, [properties?.length]);


  useEffect(() => {
    if (selectedProperty?.propertyId)
      dispatch(getPropertyUnits(selectedProperty?.propertyId));

    return () => dispatch(emptyPropertyUnits());
  }, [selectedProperty]);


  useEffect(() => {
    if (units?.length > 0) {
      const unitOptions = units.map((item, index) => ({
        value: index,
        label: item.unit?.unitCode,
      }));
      setPropertyOptions([
        {
          name: "property",
          label: "property",
          options: properties,
          search: true
        },
        {
          name: "unit",
          label: "Unit",
          options: unitOptions,
          search: true
        },
        {
          name: "status",
          label: "Status",
          options: filterStatus,
          search: true
        },
      ]);
    }


  }, [units]);


  
  useLayoutEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
       

      if (width >= 1250) {
        setScreenSize('xl');

      } else if (width >= 992) {
        setScreenSize('lg');
      } else {
        setScreenSize('smaller than lg');
      }
    };

    // Initial check
    checkScreenSize();
    // Add event listener to update on resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const onDropClick: MenuProps["onClick"] = ({ key }) => {
    setVisible(true);
    setTrigger(+key);
  };

  const getTenantsData = ()=>{
    let filter = {}
    if (selectedProperty?.propertyId && selectedUnit?.unitId) {

      filter = { propertyId: selectedProperty?.propertyId, unitId: selectedUnit?.unitId }
      setCurrentPage(1);

    } else if (selectedProperty?.propertyId) {
      filter = { propertyId: selectedProperty?.propertyId }
      setCurrentPage(1)
    } else if (selectedUnit?.unitId) {
      filter = { unitId: selectedUnit?.unitId }
      setCurrentPage(1)
    }else if(tenantStatus){

      setCurrentPage(1)
      if( tenantStatus === 'registered'){
        filter = {registered:true}
      }else if(tenantStatus === 'not_registered'){
        filter = {registered:false}
      }else if(tenantStatus ===  'inactive_deleted'){
        filter = {InactiveDeleted:true}
      }
  }
  dispatch(getTenants(id, limit, currentPage, search, searchBy, filter,setTenantLoading));
  } 

  const MoreAction = (record) => {
    const navigate: any = useNavigate();
 
    const onChnageHanldler = async (e) => {
          
      if(e === 'send_invitation'){
        setVisible(true);
        setTrigger(4);
        setSingleInvite(record.data)
      }else if(e === 'view_file'){
        navigate(`/tenants-detail/${record.data._id}`)
      }else if(e === 'restore' || e === 'deactivate'){

        PopupConfirm({
          title: `Are you sure you want to ${e === 'restore' ? 'Restore' : 'Deactivated'} ${record?.data?.name}?`,
          onOk: async () => {

            let deleteStatus = record?.data?.isDeleted ? 'false' : 'true'
            let result = await activeDeActiveUser({isDeleted:deleteStatus},record?.data?._id);

            if(result === 200){
              message.success(`${record?.data?.name} ${e === 'restore' ? 'Restore' : 'Deactivated'} successfully`);
              getTenantsData();
            }
             
             
          },
          onCancel:()=>{
            setSelectValue(!setSelect)
          },
          okText: "Yes",
        });
      }
    
    };

    const { Text } = Typography;
    return (
      <Select
        onChange={onChnageHanldler}
        className="Sentence"
        placeholder="Select an action"
        style={{ width: 150,textAlign:'center' }}
        allowClear
      >
        <>
      
              <React.Fragment >
                <Select.Option value={'view_file'}>
                  <Text>
                 
                    View File
      
                    </Text>
                </Select.Option>
                {
                
                !record.data.isSignUp && 
                (<Select.Option value={'send_invitation'}>
                  <Text>
                 
                  Send Invitation
       
                    </Text>
                </Select.Option>)
                }
              
             { 
             (record.data?.isDeleted && userInformation?.canDeactivateRestore) &&
              <Select.Option value={'restore'}>
                  <Text>
                 
                  Restore Tenant
      
                    </Text>
                </Select.Option>
                }
                

               {
               ( !record.data?.isDeleted && userInformation?.canDeactivateRestore) &&
                <Select.Option value={'deactivate'}>
                  <Text>
                 
                  Deactivate
      
                    </Text>
                </Select.Option>
                }
                
              </React.Fragment>
      
        </>
      </Select>
    );
  };

  const tenantCol = [
    {
      headerName: "Tenant Name",
      field: "name",
      checkboxSelection: (params) => {
        return !params.data.isSignUp;
      },
      headerCheckboxSelection: true,
      minWidth: screenSize === 'xl' ? 350  : 250
    },
    {
      headerName: "Mobile Number",
      field: "mobile",
      minWidth: screenSize === 'xl' ? 350 : 200
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: screenSize === 'xl' ? 350  : 250
    },
    {
      headerName: "Registered",
      field: "isSignUp",
      minWidth: screenSize === 'xl' ? 350  : 180,
      cellRenderer: (params) => (params.data.isSignUp ? "Yes" : "No"),
    },
    {
      headerName: "View Details",
      cellRendererFramework: MoreAction,
      cellStyle: { textAlign: "center" },
      filter: false,
      minWidth:screenSize === 'xl' ? 350  : 180
    },
  ];

  const onAdd = () => {
    dispatch(getTenants(id, limit, currentPage, "", "","",setTenantLoading));
  };

  const onSelectionChanged = (event) => {
    const tenants = event.api.getSelectedNodes();
    if (tenants.length > 0) {
      const tenantsIds = tenants.filter((t) => !t.data.isSignUp).map((t) => t.data.email);
      setSelectedTenants(tenantsIds);
    } else {
      setSelectedTenants([]);
    }
  };

  const onSendInvite = async ({ email }) => {
    setVisible(false);
    let successMessage = "";
    setLoading(true);
    let payload = {
      emails: [],
      usertype: "tenant",
      inviteAll: false,
    };

    if (email) {
      payload.emails.push(email);
      successMessage = "Invite sent to tenant successfully";
    } else if (selectedTenants.length > 0) {
      payload.emails = selectedTenants;
      successMessage = "Invite sent to selected tenants successfully";
    } else {
      payload.inviteAll = true;
      successMessage = "Invite sent to all tenants successfully";
    }

    const status = await sendInvite(payload, successMessage);
    setLoading(false);
    if (status === 200) {
      message.success(successMessage);
      setDeselectAll(true);
      //Can be placed at better re-rendering location
      setTimeout(() => {
        setDeselectAll(false);
      }, 1500);
    }
  };



  const handlePagination = (pg) => {
    setCurrentPage(pg);
  };


  useEffect(() => {
    if (tenantApi?.data) {
      let temp = [];
      tenantApi?.data?.map((item) => {
        temp.push({
          _id: item.userId, 
          name: item.name, 
          mobile: item.mobile, 
          email: item.email,
          isSignUp: item.isSignUp,
          isDeleted:item?.isDeleted
        });
      });
      setData(temp);
    } else {
      setData([]);
    }
  }, [tenantApi]);


  useEffect(() => {

    addToSearchHistory(searchBy, search);  //adding search history and filter store
    getTenantsData();  
  }, [search,selectedProperty, selectedUnit,tenantStatus,currentPage]);

  useEffect(() => {

    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value)
          item["default"] = true;
        else item["default"] = false;
        return item;
      })
  }, [searchHistory]);


  function onTopSearch(e) {
    if (e?.length === 0)
      setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else
      setSearch(e);
  }


  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  
  function handlePropertyChange(property, label) {
   
    let Status = filterStatus?.find((item)=>item?.value === property?.value);
    let unit = selectedUnit;
    if (label === "property" && property?.value) {
      setUpdateValue({ label: 'unit', value: null });
      setSelectedUnit(null);
      setSelectedProperty({
        propertyId: property?.value,
        property_name: property?.label,
      });
    } else if (label === "unit" && property?.value !== null) {
      unit = units[property?.value];
      setSelectedUnit(units[property?.value]);
    }else if(Status && Status?.value){
       setTenantStatus(Status?.value)
    }
  }


  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Active Tenancy
        </Title>
      ),
      content: (
        <AddLease
          onSubmit={onAdd}
       
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Add New Billing Schedule
        </Title>
      ),
      content: (
      
        <AddBilling
        
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Add New Payment Schedule
        </Title>
      ),
      content: (
       
        <AddPayment
        
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Send Invite ?
        </Title>
      ),
      content: (
        <Modal
          title='Are you sure you would like to send invitations to the selected tenants ?'
          onSubmit={() => onSendInvite({email: ''})}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Send Invite ?
        </Title>
      ),
      content: (
        <Modal
          title='Are you sure you would like to send invitation to the selected tenant ?'
          onSubmit={() => onSendInvite({email: singleInvite.email})}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <DashboardLayout load={loading}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              
              <Col span={24}>
                <SectionHeader
                  heading="Tenants"
                  noBorder={true}
                  total={tenantApi?.totalCount || 0}
                  dropNewAction={onDropClick}
                  dropItems={MenuItems}
                  dropTrigger={["click"]}

                  search={(e) => onTopSearch(e)}
                  searchBy={(e) => onTopSearchBy(e)}
                  searchOptions={searchKeys}
                  defaultSearch={search}

                  viewOption={propertyOptions}
                  viewOptionIcon={<FilterOutlined />}
                  // viewOptionLabel="Filter by : property/unit"
                  justifiedItems={true}
                  updateValue={updateValue}
                  justifyEnd={true}
                  handleView={handlePropertyChange}
             
                />
              </Col>
              <Col span={24}>
                <Pagination
                  pageSize={limit}
                  className="ag-property-text-pagination"
                  total={tenantApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Tenants`
                  }
                  defaultPageSize={tenantApi?.perPage}
                  defaultCurrent={1}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24} className="managers_tenants_detail" style={{width:'100%'}}>

              {
                tenantLoading ?
                 <LoaderTsx/>
                :

                <Card
                 style={{width:'100%'}}
                  bordered={false}
                  className="ag-nospace-body"
            
                >
                  <div className="send-invite">
                    <Button
                      type="primary"
                      icon={<MailOutlined />}
                      onClick={ () => {
                          setVisible(true);
                          setTrigger(3);
                        }
                      }
                      disabled={!(selectedTenants.length > 0)}
                    >
                      {selectedTenants.length > 0
                        ? "Invite Selected Tentants"
                        : "Invite All"}
                    </Button>
                  </div>
                  <Grid
                    data={data}
                    gridClassName="full-width-grid"
                    columns={tenantCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    noRowlabel="Tenants"
                    rowSelection="multiple"
                    onSelectionChanged={onSelectionChanged}
                    isRowSelectable={false}
                    fullPage={true}
                    deselectAll={deselectAll}
                  />
                </Card>
              }
                
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
});