import { Card, Col, Row, Typography, message, Pagination } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { dateByformat } from "../../../../../../utils/dateHelper";
import Grid from "../../../../../atoms/Grid";
import { useSelector, useDispatch } from "react-redux";
import { getPropertyUnits } from "../../../../Properties/PropertyDetail/ducks/actions";
import SectionHeader from "../../../../../molecules/SectionHeader";
import { paymentStatus } from "../../../../Properties/ducks/constants";
import { getPDC } from "../../../../Contracts/ducks/actions";
import Loader from "../../../../../modules/Loader.tsx";
import { currencyFormat } from "../../../../../../utils/media";

var _ = require("lodash");

const { Text, Title } = Typography;

interface TicketParams {
  tenantId?: String;
  propertyId?: String;
  unitId?: String;
  chequeStatus?: String;
}

export default ({ tenantData }) => {
  const [ticketsForColumn, setPaymentsForColumn] = useState([]);
  const [page, setPage] = useState(1);
  const [propertyId,setPropertyId] = useState('');
  const[unitId,setUnitID] = useState('')
  const[ticketStatus,setTicketStatus] = useState('all')
  const [limit, setLimit] = useState(30);
  const [unitLoading, setUnitLoading] = useState(false);
  const[isLoading,setIsLoading] = useState(true);

  const [pdcList, setPdcList] = useState([]);
  
  const [chequeStatus, setChequeStatus] = useState("all");
  
  const dispatch: any = useDispatch();
  const propertiesList = useSelector((state: any) => state.dashboard.propertiesDrop); 
  
  const properties = useSelector((state: any) => state.tickets.propertyname)
  let units = useSelector((state: any) => state.propertyDetails.unitsDropdown);
  const usertype = JSON.parse(window.localStorage.getItem("login-response"))?.usertype

  const userScreenAccess: any = useSelector((state: any) => state?.userScreens?.userScreenAccess);
  const PDCApi = useSelector((state: any) => state.contracts.pdcList);
  const loginUserId = JSON.parse(
    window.localStorage.getItem("login-response")
  )?.userId;
  const defaultPropertyOpt = {label: "All Properties",value: ""}
  const defaultUnitOpt = {label: unitLoading ? 'Loading...' : "All Units",value: ""}

  let allUnits = [];
  let allProperties = [];
   const [propertyOptions, setPropertyOptions] = useState([
      {
        name: "property",
        label: "Property",
        search: true,
        initialValue: "",
        options: [defaultPropertyOpt],
      },
      {
        name: "unit",
        label: "Unit",
        search: true,
        initialValue: "",
        options: [],
      },
      {
        name: "status",
        label: "Payment Status",
        initialValue: "All",
        options: paymentStatus,
      },
    ]);
 
  if(!usertype.includes("serviceProvider")){
    allProperties = propertiesList ? propertiesList :[];
    allUnits = units ? units : [];
  }else{
    allUnits = units ? units : [];
    properties?.forEach((item)=>{
      allProperties.push({
            value:item?.propertyId,
            label:item?.buildingName,
            buName:item?.businessUnitName	
      })
    })
  }

  useEffect(() => {
    dispatch(getPropertyUnits(propertyId,false,true,setUnitLoading));
  }, [propertyId]);

  const getDescription = (status: String) => {
    return status?.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const fetchPaymentsForTenant = async () => {
    setIsLoading(true);
    setPage(1);
  
    const param: TicketParams = {};
    param.tenantId = tenantData?.userDetail?.userId;
    if (propertyId) param.propertyId = propertyId;
    if (unitId) param.unitId = unitId;
    if (chequeStatus) param.chequeStatus = chequeStatus;

    dispatch(
        getPDC(
          param,
          limit,
          1,
          setIsLoading
        )
      );
  };

  useEffect(() => {
    fetchPaymentsForTenant();
  }, [propertyId, unitId, chequeStatus]);

  
  useEffect(() => {
    if (PDCApi?.data?.length > 0) {
      setPdcList(PDCApi?.data);
    } else {
      setPdcList(null);
    }
  }, [PDCApi, chequeStatus, propertyId, unitId]);

  useEffect(() => {

    let finalUnitOptions = units && units.length > 0 ? units : []
    setPropertyOptions([
      {
        name: "property",
        label: "Property",
        search: true,
        initialValue: "",
        options: [defaultPropertyOpt, ...allProperties],
      },
      {
        name: "unit",
        label: "Unit",
        search: true,
        initialValue: "",
        options: [defaultUnitOpt, ...finalUnitOptions],
      },
      {
        name: "status",
        label: "Payment Status",
        options: paymentStatus,
        initialValue: "all",

      },
    ]);
  }, [units, pdcList]);

  useEffect(() => {
    let temp = [];
    if(pdcList && pdcList.length > 0) {
      pdcList.map((payment) => {
        temp.push({
          'description': getDescription(payment.description),
          'amount': currencyFormat(payment.actualAmount),
          'vat': `${payment.vat}%`,
          'totalAmount': currencyFormat(payment.totalAmount),
          'paymentMode': convertToNormalCase(payment.modeOfPayment),
          'docNo': payment.documentNo,
          'bank': convertToNormalCase(payment.bankName),
          'dueDate':  dateByformat(payment.dueDate, "DD-MM-YYYY"),
          'chequeDate': dateByformat(payment.chequeDate, "DD-MM-YYYY"),
          'chequeStatus': convertToNormalCase(payment.chequeStatus),
        })
      })
    }
    setPaymentsForColumn(temp)
  }, [pdcList]);

  const convertToNormalCase = (input = '') => {
    // Check if the input is in camel case
    if (input?.indexOf('_') === -1 && input !== input?.toLowerCase()) {
      return input?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/\b\w/g, char => char.toUpperCase());
    }

    // Check if the input is in snake case
    if (input?.indexOf('_') !== -1) {
      return input?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    return input?.charAt(0).toUpperCase() + input?.slice(1); // Already in normal case
  }

  const pdcCol = [
    {
      headerName: "Description",
      field: "description",
    },
    {
      headerName: "Amount",
      field: "amount",
    },
    {
      headerName: "VAT",
      field: "vat",      
    },
    {
      headerName: "Total Amount",
      field: "totalAmount",
    },
    {
      headerName: "Payment Mode",
      field: "paymentMode",
    },
    {
      headerName: "Doc No.",
      field: "docNo",
    },
    {
      headerName: "Bank",
      field: "bank",
    },
    {
      headerName: "Due Date",
      field: "dueDate",
    },
    {
      headerName: "Cheque Date",
      field: "chequeDate",
    },
    {
      headerName: "Status",
      field: "chequeStatus",
      cellEditor: "agSelectCellEditor",
    },
  ];

  const loadPaginatedTickets = async (selectedCount) => {
    setIsLoading(true)
    setPdcList(null);
    setPage(selectedCount);
    const tempParam: TicketParams = {};
    tempParam.tenantId = tenantData?.userDetail?.userId;

    if (propertyId) tempParam["propertyId"] = propertyId;
    if (unitId) tempParam["unitId"] = unitId;
    if(chequeStatus) tempParam["chequeStatus"] = chequeStatus;
      tempParam["chequeStatus"] = chequeStatus;
      dispatch(getPDC(
        tempParam,
        limit,
        selectedCount,
        setIsLoading,
      )
    )
  };

  const searchTrigger = (e) => {
  if(e?.value.includes('propertyId') || e?.value === ''){
      setPropertyId(e?.value);
      setUnitID(null)
      dispatch(getPropertyUnits( e?.value === '' ? false : e?.value,false,true,setUnitLoading));
    }else if(e?.value.includes('unitId') || e?.value === ''){
      setUnitID(e?.value);
    } else {
      setChequeStatus(e?.value);
    }
  };

  return (
    <>
      <Col span={24}>
        <SectionHeader
          heading="Payments"
          noBorder={true}
          total={PDCApi?.totalCount || 0}
          viewOption={propertyOptions}
          justifiedItems={true}
          justifyEnd={true}
          bottom={true}
          right={true}
          handleView={searchTrigger}
          onChange={(e) => {searchTrigger(e)}}
        />
        </Col>
      {isLoading ? <Loader/> : 
      <Card bordered={false}>
        <Row gutter={[60, 0]}>
          <Col span={24}>
            <Col span={24}>
            <Pagination
                className="ag-property-text-pagination"
                total={PDCApi?.totalCount || 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Payments`
                }
                showSizeChanger={false}
                pageSize={PDCApi?.perPage || 10}
                defaultPageSize={30}
                defaultCurrent={1}
                current={page}
                onChange={(e) => loadPaginatedTickets(e)}
              />
            </Col>
            <Grid
              data={ticketsForColumn}
              columns={pdcCol}
              pagination={false}
              noRowlabel="Lease"
              fullPage={true}
            />
          </Col>
        </Row>
      </Card>
      }
    </>
  );
};
