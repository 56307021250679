import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  propertyService,
  unitService,
} from "../../../../configs/constants";
import {message} from 'antd';

export const getUnits = (
  bySearch,
  limit,
  page,
  search,
  searchBy,
  filter = {},
  setExportData = null,
  paymentPlan = false,
  loadingStatus = null,
  setHasExportDataBeenSet
) => {
  let tempFilter: any = { ...filter };
  loadingStatus && loadingStatus(true);

  if (tempFilter['usagesType'] === 'all') {
    delete tempFilter.usagesType;
  }

  if (tempFilter['unitStatus'] === 'all') {
    delete tempFilter.unitStatus;
  }

  return async (dispatch) => {
    let params = new URLSearchParams({ ...tempFilter });
    let tenants = "";
    let url = "";

    try {
      // Determine URL based on the search flag
      if (bySearch) {
        tenants = `${unitService}/unit/unitList`; // /leasing/getAll`;
        url = `${tenants}?&perPage=${limit}&page=${page}`;
      } else {
        tenants = `${unitService}/unit/unitList`; // /leasing/getAll`;
        url = `${tenants}?perPage=${limit}&page=${page}&${searchBy}=${search}&paymentPlan=${paymentPlan}&${params.toString()}`;
      }

      const {
        data: { result },
      } = await axios.get(url);
      // export data logic if export is clicked
      if (filter['exportData']) {
        message.success(result);
        setExportData(false);
        loadingStatus && loadingStatus(false);
        setHasExportDataBeenSet(true); // flag to prevent one unnecessary api calls after export
      } else {
        dispatch({
          type: action_types.GET_UNITS,
          data: result,
        });
        loadingStatus && loadingStatus(false);
      }
    } catch (error) {
      loadingStatus && loadingStatus(false);
      console.error(error);
    }
  };
};


export const setFilters = data => {
  
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_UNIT_FILTERS,
      data: data,
    });
  }
}

export const clearFilters = () => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_UNIT_FILTERS,
      data: {},
    });
  }
}

export const getUnitDetails = async (id) => {

  const url = `${propertyService}/leasing/getById?unitId=${id}`;
  
  const {
    data: { result },
  } = await axios.get(url);

  return result
};