import React, { useState, useEffect,useLayoutEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  MenuProps,
  message,
  Typography,
  Tooltip,
  Select
} from "antd";
import { currencyFormat } from "../../../utils/media";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import * as status_types from "../../atoms/PropertyCard/constants";
import { useDispatch, useSelector } from "react-redux";
import { getUnits, getUnitDetails, setFilters } from "./ducks/actions";
import { useNavigate } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterUnits } from "../App/SearchFilters/ducks/actions";
import { unitBookingStatus } from "../BookingForm/components/utils";
import { Modal } from "../BookingForm/components";
import LoaderTsx from "../Loader.tsx";
import Permissions from "../../../utils/permissions";
import { MODULES } from "../../../configs/modules";
import { READ } from "../../../configs/operations";
import { checkScreenAccess } from "../../../utils/helper";


const gridProps = {
  sortable: true,
  // filter: true,
  resizable: true,
  type: 'fitCellContents',
  // minWidth: 100,
};

const limit = 50;

const initialSearchKeys = [
  { value: "unitCode", label: "Unit No" },
  { value: "propertyName", label: "Property Name" },
  { value: "propertyCode", label: "Property Code" },
  { value: "location", label: "Location" }, //changed from community ref: 8405
];

const { Text } = Typography;

export default (props) => {

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [searchKeys, setSearchKeys] = useState(initialSearchKeys);
  const unitsApi = useSelector((state: any) => state.units.unitsList);
  const unitsFilters = useSelector((state: any) => state.units.unitsFilters);
  const [updateValue, setUpdateValue] = useState({});
  const userScreenAccess: any = useSelector((state: any) => state?.userScreens?.userScreenAccess);

  const defaultFilterByValues = { unitStatus: 'vacant', usagesType: "all" };

  const [currentPage, setCurrentPage] = useState(unitsFilters?.currentPage || 1);
  const { searchHistory } = useSearchHistory(setFilterUnits);
  const [searchBy, setSearchBy] = useState(unitsFilters?.searchBy ? unitsFilters?.searchBy : "propertyName")  //: Filter ? Object.keys(Filter)[0]  // (Object.keys(Filter)[0] ?? "propertyName")
  const [search, setSearch] = useState(unitsFilters?.search ? unitsFilters?.search : "");
  const [filterBy, setfilterBy] = useState(unitsFilters?.filterBy || defaultFilterByValues)
  const [loading, setLoading] = useState(false);
  const [deselectAll, setDeselectAll] = useState(false);
  const [unitPaymentPlans, setUnitPaymentPlan] = useState([]);
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);
  const [isLoading,setUnitLoading]:any = useState(false);
  const[exportData,setExportData] = useState(false);
  const [hasExportDataBeenSet, setHasExportDataBeenSet] = useState(false);
  const [screenSize, setScreenSize] = React?.useState('lg');
  
  useLayoutEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
     
      if (width >= 1900) {
        setScreenSize('xl');

      } else if (width >= 992) {
        setScreenSize('lg');
      } else {
        setScreenSize('smaller than lg');
      }
    };

    // Initial check
    checkScreenSize();
    // Add event listener to update on resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);


  let modalPayload: any = {
    iKey: "8",
    width: "55%",
    title: "Payment Plans",
    extraText: ""
    //   "You can add a new payment plan, edit, or disable an existing payment plan. If you add or edit a payment plan, you\nneed to send an approval request.",
  };

  const MoreAction = (record) => {
    const navigate: any = useNavigate();
    const { Text } = Typography;

    const onChangeHanldler = async (e) => {
      if (e === 'view_unit') {
        navigate(`/property-detail/${record.data?.responce?.propertyId}/unit/${record.data?.responce?._id}`, {
          state: {
            parentname: record?.data?.responce?.propertyName, showNav: true, usageType: record?.data?.responce?.unit?.usagesType
          },
        })
      } else if (e === 'add_booking') {
        navigate(`/newbookingform`,
          {
            state: {
              selectedUnit: {
                unit: record?.data?.responce?.unit,
                bookingPeriod: record?.data?.responce?.bookingPeriod,
                businessUnitId: record?.data?.responce?.businessUnitId,
                businessUnitName: record?.data?.responce?.businessUnitName,
                unitId: record?.data?.responce?.unitId
              },
              selectedProperty: { property_name: record?.data?.responce?.propertyName, propertyId: record?.data?.responce?.propertyId }
            }
          })
      } else if (e === 'reserved' || e === 'occupied') {
        getInnerUnitDetails(record.data.unitId, "occupied")
      } else if (e === 'view_price') {

        setShowPaymentPlan(true);
        setUnitPaymentPlan(record?.data?.responce?.paymentPlan);

      }
    };

    return (
      <Select
        onChange={onChangeHanldler}
        className="Sentence"
        placeholder="Select an action"
        style={{ width: 150, textAlign: 'center' }}
        allowClear
      >
        <>

          <React.Fragment >
            <Select.Option value={'view_unit'}>
              <Text>
                View Unit
              </Text>
            </Select.Option>

            {
              record.data.unitStatus === "vacant" && Permissions.isAllowed(MODULES.PROPERTIES, [READ]) && checkScreenAccess("bookings", false, userScreenAccess) &&
              <Select.Option value={'add_booking'} disabled={record?.data?.responce?.blockData?.block}>
                <Text>
                  Add Booking
                </Text>
              </Select.Option>
            }

            {
              record.data.unitStatus === "reserved" && Permissions.isAllowed(MODULES.PROPERTIES, [READ]) && checkScreenAccess("bookings", false, userScreenAccess) &&
              <Select.Option value={'reserved'}>
                <Text>
                  View Booking
                </Text>
              </Select.Option>
            }

            {
              record.data.unitStatus === "occupied" && Permissions.isAllowed(MODULES.PROPERTIES, [READ]) && checkScreenAccess("leases", false, userScreenAccess) &&
              <Select.Option value={'occupied'}>
                <Text>
                  View Lease
                </Text>
              </Select.Option>
            }

            {

              record?.data?.responce?.paymentPlan[0]?.unitPaymentPlanId  && <Select.Option value={'view_price'}>
                <Text>
                  Show Pricing
                </Text>
              </Select.Option>
            }

          </React.Fragment>

        </>
      </Select>
    );
  };
  
  const MenuItems: MenuProps["items"] = [
    {
      label: "Active Tenancy",
      key: 0,
    },
    {
      label: "Payment Schedule",
      key: 2,
    },
  ];

  const defaultFilterOptions = [
    {
      name: "unitStatus",
      label: "Status",
      initialValue: unitsFilters.filterBy?.unitStatus || defaultFilterByValues?.unitStatus,
      options: [
        {
          value: 'all',
          label: 'All'
        },
        {
          value: 'vacant',
          label: 'Vacant',
        },
        {
          value: 'reserved',
          label: 'Reserved'
        },
        {
          value: 'occupied',
          label: 'Occupied'
        },
        {
          value: 'blocked',
          label: 'Blocked'
        },
      ],
    },
    {
      name: "usagesType",
      label: "Usage Type",
      initialValue: unitsFilters.filterBy?.usagesType || defaultFilterByValues?.usagesType,
      options: [
        {
          value: 'all',
          label: 'All'
        },
        {
          value: 'residential',
          label: 'Residential',
        },
        {
          value: 'commercial',
          label: 'Commercial'
        }
      ],
    }
  ];

  const [propertyOptions, setPropertyOptions] = useState(defaultFilterOptions);


  const Statuses = (stat) => {
    switch (stat) {
      case status_types.PROPERTY_ENABLED:
        return "Active";
      case status_types.PROPERTY_DISABLED:
        return "Disabled";
      case status_types.PROPERTY_DRAFT:
        return "Vacant";
      case status_types.PROPERTY_RENTED:
        return "Occupied";
      case status_types.PROPERTY_PUBLISHED:
        return "Vacant";
      case status_types.PROPERTY_RESERVED:
        return "Reserved";
      case status_types.PROPERTY_BLOCKED:
        return "Blocked";
    }
  };

  const convertToNormalCase = (input = '') => {
    // Check if the input is in camel case
    if (input?.indexOf('_') === -1 && input !== input?.toLowerCase()) {
      return input?.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    // Check if the input is in snake case
    if (input?.indexOf('_') !== -1) {
      return input?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    return input?.charAt(0).toUpperCase() + input?.slice(1); // Already in normal case
  }

  const tenantCol = [
    {
      headerName: "Status",
      field: "unitStatus",
      width: 100,
      cellRendererFramework: ({ data }) => (
        <Text
          className={`statusUnit 
          ${data?.unitStatus === "disabled" ? "inactive" : ""} 
          ${data?.unitStatus === "occupied" ? "inactive" : ""} 
          ${data?.unitStatus === "reserved" ? "inactive-orange" : ""}
          ${data?.responce?.blockData?.block ? "inactive-block" : ""}
            `} 
        >
          {Statuses(data?.responce?.blockData?.block ? 'blocked' : data?.unitStatus)}
        </Text>

      ),
    },

    {
      headerName: "Sub Status",
      field: "leaseStatus",
      hide: (filterBy?.unitStatus === 'reserved' ||  filterBy?.unitStatus === 'all')  ? false : true,
      width: 270,
      cellRendererFramework: (record) => {
        if (record?.data?.unitStatus === 'reserved')
          return `${unitBookingStatus(record?.data?.responce?.leaseStatus)} - ${convertToNormalCase(record?.data?.responce?.leaseStatus)}`;
        else return '--';
      }
    },

    {
      headerName: "Unit Type",
      field: "unitType",
      width: 120
    },
    {
      headerName: "Unit No",
      field: "unitNo",
      width: 160,
    },
    {
      headerName: "Property",
      field: "property",
      // width: 180
    },
    {
      headerName: "Location",
      field: "location",
      width: 200
    },

    {
      headerName: "Rent (4 Inst.)",
      field: "paymentPlan",
      width: 130,
      cellRendererFramework: (record) => {

        if (record?.data?.responce?.paymentPlan?.length > 0) {
          const rent_4_payment = record?.data?.responce?.paymentPlan?.find((item) => item?.installments === '4' && item?.furnished.toLowerCase() === "no");
          if (rent_4_payment?.furnished.toLowerCase() === "no" && rent_4_payment?.installments === "4") {
            return (<div style={{position:'absolute',left:'25px'}} className="annualRent">{currencyFormat(Number(rent_4_payment?.annualRent))}</div>)
          } else return '--';

        }


      }
    },

    {
      headerName: "Beds",
      field: "unitNumber",
      width: 200
    },

    {
      headerName: "Built-up Area",
      field: "BuiltUpArea",
      width: 130
    },
    {
      headerName: "Plot Area",
      field: "PlotArea",
      width: 120
    },
    {
      headerName: "No. of Bookings",
      field: "PlotArea",
      width: screenSize === 'xl' ? 600 : 200,
      cellRendererFramework: (record) => (
        record?.data?.responce?.bookingCount ? (
          <Tooltip title="View bookings" trigger="hover" showArrow={false}>
            <Button onClick={() => navigate(`/bookings`, { state: { unitId: record?.data?.unitNo } })} type="link" className="">
              {record?.data?.responce?.bookingCount} ↗
            </Button>
          </Tooltip>
        ) : ""
      )
    },
    {
      headerName: "View Details",
      cellRendererFramework: MoreAction,
      cellStyle: { textAlign: "right" },
      filter: false,
      width: 200,

      pinned: 'right'
    },
  ];

  const getInnerUnitDetails = async (id, type) => {
    setLoading(true);
    try {
      const unit = await getUnitDetails(id);
      if (unit?.leaseStatus && unit?.leaseStatus != "draft")  //ADDED CHECKS TO FIX DRAFT/NONDRAFT FORM ISSUE: REF->8142
        navigate(`/viewbookingform/${unit.leaseId}`);
      else navigate(`/newbookingform/${unit.leaseId}`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
  };

  useEffect(() => {
    if (unitsApi?.data) {
      let temp = [];
      unitsApi?.data?.map((item) => {
        temp.push({
          unitId: item.unitId,
          unitStatus: item.unitStatus,
          unitType: item?.unit?.unitType,
          location: `${item?.address?.community || ""} ${item?.address?.subCommunity ? (", " + item?.address?.subCommunity) : ""}`, //{item?.address?.addressLine2}, ${item?.address?.addressLine1}`,
          property: item.propertyName,
          unitNo: item?.unit?.unitCode,
          unitNumber: item?.unit?.unitSubType,
          BuiltUpArea: item?.unit?.areaSqft,
          PlotArea: item?.unit?.suiteSize,
          responce: item,
        });
      });
      setData(temp);
    } else {
      setData([]);
    }
  }, [unitsApi]);

  useEffect(() => {
    if (unitsFilters?.searchBy) {
      const setArray = searchKeys.map((item) => {
        let val = null;
        if (item.value === unitsFilters?.searchBy) {
          val = { ...item, default: true };
        } else {
          val = { ...item, default: false };
        }
        return val;
      });
      setSearchKeys(setArray);
    }
  }, [unitsFilters, unitsFilters?.searchBy]);

  useEffect(() => {
    if (Object.keys(unitsFilters).length === 0 || unitsFilters.constructor !== Object || hasExportDataBeenSet) return;
  
    setPropertyOptions(defaultFilterOptions);
  
    let paymentPlan = true;
    const params = { ...unitsFilters?.filterBy };
  
    if (exportData) {
      params['exportData'] = exportData;
      params['totalCount'] = unitsApi?.totalCount;
    }
  
    dispatch(
      getUnits(
        false,
        limit,
        unitsFilters.currentPage,
        unitsFilters.search,
        unitsFilters.searchBy,
        params,
        setExportData,
        paymentPlan,
        setUnitLoading,
        setHasExportDataBeenSet
      )
    );
  }, [unitsFilters, exportData, hasExportDataBeenSet]);
  

  useEffect(() => {
    let currPage = currentPage;
    if (
      unitsFilters?.search != search ||
      unitsFilters?.searchBy != searchBy ||
      unitsFilters?.filterBy != filterBy
    )
      currPage = 1;
    dispatch(setFilters({ search, searchBy, filterBy, currentPage: currPage }));
    setHasExportDataBeenSet(false);
  }, [search, searchBy, filterBy, currentPage]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
  }, [searchHistory]);

  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  function handlePropertyChange(property, label) {
    setfilterBy({ ...filterBy, [label]: property.value });
  }

  const exportAction = ()=>{
    setExportData(true)
  }

  return (
    <>
      <DashboardLayout load={loading}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Units"
                  noBorder={true}
                  total={unitsApi?.totalCount || 0}

                  dropItems={MenuItems}
                  dropTrigger={["click"]}

                  search={(e) => onTopSearch(e)}
                  searchBy={(e) => onTopSearchBy(e)}
                  searchOptions={searchKeys}
                  defaultSearch={search}
                  downloadAction={exportAction}
                  viewOption={propertyOptions}
                  viewOptionIcon={<FilterOutlined />}
                  // viewOptionLabel="Filter by : status/usage"
                  justifiedItems={true}
                  updateValue={updateValue}
                  justifyEnd={true}
                  handleView={handlePropertyChange}
                />
              </Col>
              <Col span={24}>

                <Pagination
                  className="ag-property-text-pagination"
                  total={unitsApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Units`
                  }
                  showSizeChanger={false}
                  defaultPageSize={50}
                  defaultCurrent={1}
                  current={unitsFilters?.currentPage || currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24} className="managers_tenants_detail">
                <Card bordered={false} className="ag-nospace-body">

                  {
                    isLoading ?
                    <LoaderTsx/>
                    :
                    <Grid
                    data={data}
                    columns={tenantCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    noRowlabel="Tenants"
                    rowSelection="multiple"
                    isRowSelectable={false}
                    fullPage={true}
                    deselectAll={deselectAll}
                  />
                  }
                 
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        
        <Modal
          className="bd-modal"
          unitPaymentPlan={unitPaymentPlans}
          modalPayload={modalPayload}
          showPaymentPlan={showPaymentPlan}
          setShowPaymentPlan={setShowPaymentPlan}


        />
      </DashboardLayout>
    </>
  );
};
