// others
import { Dispatch } from "redux";
import {
  CREATE_RENEWAL,
  SET_FORM_STATE,
  CREATE_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  GET_CONFIG_DATA,
  SET_BOOKING_FORM,
  GET_PAYMENT_PLANS,
  GET_BOOKING_DETAIL,
  CLEAR_BOOKING_FORM,
  GET_LANDLORD_USERS,
  GET_BOOKING_UNIT_DETAILS,
  GET_TENANT_DETAILS_BY_EMAIL,
  GET_TEMPLATES_DATA,
  GET_PARKING_BAYS,
  REFRESH_RENWAL
} from "./types";
import {
  getTermsService,
  createRenewalService,
  deleteBookingService,
  getConfigDataService,
  updateBookingService,
  creatreBookingService,
  getPaymentPlansService,
  getBookingDetailService,
  getLandlordUsersService,
  getBookingUnitDetailService,
  getTenantDetailByEmailService,
  generateDocumentService,
  saveDocumentToLeaseService,
  updateBookingStatusService,
  getTemplateDocumentService,
  sendContractForSignService,
  getParkingBayService,
} from "./services";

// clear booking form
export const clearBookingForm = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_BOOKING_FORM,
    });
  };
};

// set booking form
export const setBookingForm = (data: Object) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_BOOKING_FORM,
      data: data,
    });
  };
};

// set form tracker
export const setFormState = (type?: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_FORM_STATE,
      data: type,
    });
  };
};

// get config data action
export const getConfigData = (keys: Array<string>) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CONFIG_DATA,
      data: await getConfigDataService(keys),
    });
  };
};

// get booking detail action
export const getBookingDetail = (leaseId: string) => {

  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_BOOKING_DETAIL,
      data: await getBookingDetailService(leaseId),
    });
  };
};

// get booking detail action
export const getBookingUnitDetail = (unitId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_BOOKING_UNIT_DETAILS,
      data: await getBookingUnitDetailService(unitId),
    });
  };
};

export const emptyCreateBooking = () => {
  return async (dispatch: Dispatch) => {

    dispatch({
      type: CLEAR_BOOKING_FORM
    });
    
  };
};

// create booking action
export const createBooking = (data: object) => {
  return async (dispatch: Dispatch) => {
    let response = await creatreBookingService(data);

    dispatch({
      type: CREATE_BOOKING,
      data: {},
    });

    return response;
  };
};

// update booking action
export const updateBooking = (data: object) => {

 
  return async (dispatch: Dispatch) => {
    let response = await updateBookingService(data);

    dispatch({
      type: UPDATE_BOOKING,
      data: response,
    });

    return response;
  };
 
};

export const getLeaseTerms = (propertyId: any, usagesType: any) => {
  return async (dispatch: Dispatch) => {
    let response = await getTermsService(propertyId, usagesType);
    return response;
  };
};

// update booking action
export const generateDocument = (data: object) => {
  return async (dispatch: Dispatch) => {
    let response = await generateDocumentService(data);

    dispatch({
      type: UPDATE_BOOKING,
      data: response,
    });

    return response;
  };
};

// create renewal action
export const createRenewal = (data: object) => {
  return async (dispatch: Dispatch) => {
    let response = await createRenewalService(data);

    dispatch({
      type: CREATE_RENEWAL,
      data: response,
    });

    return response;
  };
};

// get tenant detail by email action
export const getTenantDetailByEmail = (email: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TENANT_DETAILS_BY_EMAIL,
      data: await getTenantDetailByEmailService(email),
    });
  };
};

// get landlord users action
export const getLandlordUsers = (propertyId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LANDLORD_USERS,
      data: await getLandlordUsersService(propertyId),
    });
  };
};

// delete booking action
export const deleteBooking = (leaseId: string) => {
  return async (dispatch: Dispatch) => {
    let response = await deleteBookingService(leaseId);

    dispatch({
      type: DELETE_BOOKING,
      data: response,
    });

    return response;
  };
};

// get paymentplans by furnished
export const getPaymentPlans = (unit: string, furnished: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PAYMENT_PLANS,
      data: await getPaymentPlansService(unit, furnished),
    });
  };
};

// save document to lease action
export const saveDocumentToLease = (data: object) => {
  return async (dispatch: Dispatch) => {
    let response = await saveDocumentToLeaseService(data);

    dispatch({
      type: UPDATE_BOOKING,
      data: response,
    });

    return response;
  };
};

// update booking status action
export const updateBookingStatus = (data: object) => {
  return async (dispatch: Dispatch) => {
    let response = await updateBookingStatusService(data);

    dispatch({
      type: UPDATE_BOOKING,
      data: response,
    });

    return response;
  };
};

// get config data action
export const getTemplateDocuments = (data?: Array<string>) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TEMPLATES_DATA,
      data: await getTemplateDocumentService(),
    });
  };  
};

// update renewal if renewal status is renewal_sent or renewal decline in sprint 42 story 9986

export const RefreshRenwalData = (data?: Array<string>) => {
  return async (dispatch: Dispatch,getState) => {

    const refresh_renwal = getState();
    dispatch({
      type: REFRESH_RENWAL,
      data: !refresh_renwal?.newBooking?.refresh_renwal,
    });
  };  
};

// update booking status action
export const sendContractForSign = (data: object) => {
  return async (dispatch: Dispatch) => {
    let response = await sendContractForSignService(data);
    dispatch({
      type: UPDATE_BOOKING,
      data: response,
    });

    return response;
  };
};

// get parkings data action
export const getParkingBays = (data?: object) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PARKING_BAYS,
      data: await getParkingBayService(data),
    });
  };  
};