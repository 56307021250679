import { Card, Col, Row, Typography, Button } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { dateByformat } from "../../../../../../utils/dateHelper";
import Grid from "../../../../../atoms/Grid";
import { statusLabel } from "../../../../../../utils/media";
import { ArrowRightOutlined } from "@ant-design/icons";
import Permissions from "../../../../../../utils/permissions";
import { MODULES } from "../../../../../../configs/modules";
import { READ } from "../../../../../../configs/operations";
import { checkScreenAccess } from "../../../../../../utils/helper";
import { useSelector } from "react-redux";
import { formatString } from "../../../../../../utils/formatString";

var _ = require("lodash");

const { Text } = Typography;

function viewRender(param) {

  // let _text = "";
  // if (param?.data?.renewalStatus) { 
  //   if (param.data.renewalStatus == 'renewal_declined')
  //     _text = statusLabel(param.data.renewalStatus).toString();
  //  else if(param.data.renewalStatus == 'moveout_confirmed')
  //     _text = statusLabel(param.data.renewalStatus).toString();
  //  else if (param.data.renewalStatus == 'renewal_accepted' && (param.data.leaseStatus == "active" || param.data.leaseStatus == "renewed"))
  //     _text = statusLabel(param.data.leaseStatus).toString();
  //  else if(param.data.renewalStatus == "active" || param.data.leaseStatus == "renewed")
  //   _text = statusLabel(param.data.renewalStatus).toString();
  //  else _text = 'Renewal in Progress';
  // } else _text = statusLabel(param?.value).toString() || "";
 
  return <Text className="f-16">{formatString(param.data.leaseStatus)}</Text>;

}

export default ({ tenantData }) => {
  const [leaseData, setLeaseData] = useState([]);
  const userScreenAccess: any = useSelector((state: any) => state?.userScreens?.userScreenAccess);

  useEffect(() => {

    let temp = [];

    _.map(tenantData?.leaseDetail, item => {

      const property = item?.propertyDetails;
      const lease = item?.leaseTerms;
      temp.push({
        "key": item?._id,
        "propertyName": property?.propertyName ?? "NA",
        "unitCode": property?.unitCode ?? "NA",
        "usagesType": property?.usagesType ?? "NA",
        "unitType": property?.unitType ?? "NA",
        "unitNumber": property?.unitNumber ?? "NA",
        "areaSqft": property?.areaSqft ?? "NA",
        "unitId": item?.unitId,
        "leaseId": item?.leaseId,
        "leaseStartDate": lease?.leaseStartDate ?? "NA",
        "leaseEndDate": lease?.leaseEndDate ?? "NA",
        //leaseStatus
        "canView": item?.canView ?? null,
        "leaseStatus": item?.leaseStatus,
        "renewalStatus": item?.renewalStatus,
      });
    });


    setLeaseData(temp);
  }, [tenantData]);

  const columns = [
    {
      headerName: "Property Name",
      field: "propertyName",
      maxWidth: 350,
    },
    {
      headerName: "Unit Number",
      field: "unitNumber",
    },
    {
      headerName: "Unit Code",
      field: "unitCode",
      minWidth: 250,
      maxWidth: 320,
    },
    {
      headerName: "Type",
      field: "usagesType",
    },
    {
      headerName: "Unit Type",
      field: "unitType",
    },
    {
      headerName: "Lease Start Date",
      field: "leaseStartDate",
      cellRendererFramework: (item) => dateByformat(item.data.leaseStartDate, "DD-MM-YYYY"),

    },
    {
      headerName: "Lease End Date",
      field: "leaseEndDate",
      cellRendererFramework: (item) => dateByformat(item.data.leaseEndDate, "DD-MM-YYYY"),

    },
    {
      headerName: "Lease Status",
      field: "leaseStatus",
      maxWidth: 300,
      minWidth: 180,
      cellRendererFramework: viewRender,
    },
    {
      headerName: "Action",
      maxWidth: 300,
      minWidth: 180,
      cellRendererFramework: viewRenderLease,
    },
  ];

  const getInnerUnitDetails = async (data) => {
    try {
      // const unit = await getUnitDetails(data.unitId)
      window.open(`/viewbookingform/${data.leaseId}`, '_blank');
    } catch (error) {
      console.log(error);
    }
  }

  function viewRenderLease(param) {
    if(!Permissions.isAllowed(MODULES.PROPERTIES, [READ]) || !checkScreenAccess("bookings", false, userScreenAccess) || !checkScreenAccess("leases", false, userScreenAccess)) {
      return
    }
    let _text = "booking";
    if (param?.data?.renewalStatus) { 
      if (param.data.renewalStatus == 'renewal_declined')
        _text = 'booking';
      else if (param.data.renewalStatus == 'renewal_accepted' && (param.data.leaseStatus == "active" || param.data.leaseStatus == "renewed"))
        _text = 'lease'
      else _text = 'renewal';
    } else if (param.data.leaseStatus == "active") _text = 'lease'; else _text = 'booking';

    return (<div onClick={() => param.data.canView && getInnerUnitDetails(param.data)}>
      <a style={param.data.canView ? {} : { color: 'grey' }} className={`f-16`} target="_blank" >
        {/* {(param?.data?.leaseStatus === 'active' || param?.data?.leaseStatus === 'lease_expired' || param.data.renewalStatus == 'renewal_accepted') ? `Go to lease` : 'Go to booking'} */}
        {`Go to ${_text}`}
      </a>
      <ArrowRightOutlined style={{ marginLeft: 5, cursor: 'pointer', paddingTop: 2, color: 'grey' }} />
    </div>);
  }

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Grid
            data={leaseData}
            columns={columns}
            pagination={false}
            noRowlabel="Lease"
          />
        </Col>
      </Row>
    </Card>
  );
};
