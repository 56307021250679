// others
import Loading from "./Loading";
import AddDocs from "./AddDocs";
import AddPayment from "./AddPayment";
import LeaseTerms from "./LeaseTerms";
import GenerateDoc from "./GenerateDoc";
import PaymentPlan from "./PaymentPlan";
import ChangeHistory from "./ChangeHistory";
import TenantDetails from "./TenantDetails";
import RenewalTermsModal from "./RenewalTerms";
import PendingRenewals from "./PendingRenewals";
import EditPaymentSchedule from "./EditPaymentSchedule";
import EditTerminationProcess from "./EditTerminationProcess"
export {
  AddDocs,
  Loading,
  AddPayment,
  LeaseTerms,
  GenerateDoc,
  PaymentPlan,
  TenantDetails,
  ChangeHistory,
  PendingRenewals,
  RenewalTermsModal,
  EditPaymentSchedule,
  EditTerminationProcess
};
