import React, { FC, Fragment } from 'react'
import { Info } from '../../../../assets/svg';
import { filterDropdownValue, filterKycSelectList, KYC_CONTENT, kycInitValue } from './utils';
import { Calendar, Col } from 'antd';
import { DateField, InputField, ReactSelectField } from '../../../atoms/FormElement';

interface IProps {}

/**
* @author
* @function @
**/

const  kycDetails = (props) => {

    const {kycDetail,control,errors,bookingDetails,setValue,colSpan} = props ;


  return (
    <>
    {props?.kycDetail.map((item: any, index: number) => {
        const tooltip = {
          title: KYC_CONTENT,
          icon: <Info width="16px" height="16px" />,
        };

        return (
          <Fragment key={item?._id}>
            {["text", "number", "numberWithComma"].includes(
              item.type
            ) && (
              <Col span={colSpan}>
                <InputField
                  isRequired={item?.rules?.required ? true : false}
                  control={control}
                  label={item.label}
                  fieldname={`kyc-${item.name}`}
                  validate={errors[`kyc-${item.name}`] && "error"}
                  validMessage={
                  errors[`kyc-${item.name}`] && errors[`kyc-${item.name}`].message
              }
                  iProps={{ placeholder: item.label }}
                  initValue={kycInitValue(
                    item.name,
                    bookingDetails?.kycDetails 
                  )}
                  {...{ tooltip }}
                  rules={item?.rules?.required ? {required:`${item.label} is required`}: item?.rules}
                />
              
              </Col>
            )}

            {["date"].includes(item.type) && (
              <Col span={colSpan}>
                <DateField
                  control={control}
                  label={item.label}
                  isRequired={item?.rules?.required ? true : false}
                  setValue={setValue}
                  fieldname={`kyc-${item.name}`}
                  iProps={{
                    suffixIcon: <Calendar />,
                    placeholder: "DD-MM-YYYY",
                  }}
                  initValue={kycInitValue(
                    item.name,
                    bookingDetails?.kycDetails
                  )}
                  {...{ tooltip }}
                  validate={errors[`kyc-${item.name}`] && "error"} 
                  validMessage={
                    errors[`kyc-${item.name}`] && errors[`kyc-${item.name}`].message
                }
                />
               
              </Col>
            )}

            {["select"].includes(item.type) && (
              <Col span={colSpan}>
                <ReactSelectField
                  control={control}
                  label={item.label}
                  fieldname={`kyc-${item.name}`}
                  isRequired={item?.rules?.required ? true : false}
                  setValue={setValue}
                  iProps={{ placeholder: item.label }}
                  selectOption={filterKycSelectList(item.options[0])}
                  validate={errors[`kyc-${item.name}`] && "error"} 
                  rules={item?.rules?.required ? {required:`${item.label} is required`}: item?.rules}
                  validMessage={
                  errors[`kyc-${item.name}`] && errors[`kyc-${item.name}`].message
              }
                  initValue={filterDropdownValue(
                    kycInitValue(item.name, bookingDetails?.kycDetails),
                    filterKycSelectList(item.options[0])
                  )}
                  {...{ tooltip }}
                />
              </Col>
            )}
          </Fragment>
        );
      })}
    </>
   )
 }

 export default React.memo(kycDetails);
