import React, { useEffect, useState } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import { bookingStatuses } from "../../../utils/helper";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Select,
  message,
  Typography,
  Tag,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import {
  InfoCircleOutlined,

} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import LoaderTsx from "../Loader.tsx";
import {
  getBookingForms,
  getBookingPendingReq,
  getLeasePendingReq,
  getPlanPendingReq,
  sendTenantInvite,
  updateBookingStatus,
} from "./ducks/actions";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../utils/axiosInceptor";
import { baseContractService } from "../../../configs/constants";
import {
  getBookingUnitList,
  getPropertyUnits,
} from "../Properties/PropertyDetail/ducks/actions";
import { getPropertiesDrop } from "../Properties/ducks/actions";
import { Popup, PopupConfirm } from "../../atoms/Popup";
import { dateByformat } from "../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../utils/media";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterBooking } from "../App/SearchFilters/ducks/actions";
import Title from "antd/es/typography/Title";
import BookingLeaseRequests from "./bookingLeaseRequests/bookingLeaseRequests";
import {
  clearBookingForm,
  emptyCreateBooking,
} from "../BookingForm/ducks/actions";
var _ = require("lodash");
const gridProps = {
  resizable: true,

};

const dropDown = [
  { id: 2, name: "Edit", status: "" },
  { id: 3, name: "Send To Tenant", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];

const dropDownRenewal = [,
  { id: 2, name: "Edit", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];

const dropDown1 = [,
  { id: 2, name: "Edit", status: "" },
];
const dropDown2 = [
  { id: 2, name: "View Confirmed Form", status: "" },
];
const dropDown3 = [
  { id: 4, name: "View Approved Form", status: "" },
];
const limit = 50;
const searchKeys = [
  { value: "name", label: "Name" },
  { value: "unitCode", label: "Unit Number", default: true },
  { value: "property", label: "Property" },
  { value: "leaseExecutive", label: "Lease Executive" },  
  { value: "bookingId", label: "Booking ID" },
  { value: "mobileNumber", label: "Phone" },
  { value: "email", label: "Email" },
];
const BookingListing = (props) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const listBooking = useSelector((state: any) => state.booking.bookingList);
  const units = useSelector((state: any) => state.propertyDetails.bookingUnits);
  const pendingBookingRequests = useSelector((state: any) => state.booking.bookingPendingRequests);
  const pendingLeaseRequests = useSelector((state: any) => state.booking.leasePendingRequests);
  const pendingPlanRequests = useSelector((state: any) => state.booking.planPendingRequests);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [updateValue, setUpdateValue] = useState({});
  const [bookingStatus, setBookingStatusFilter] = useState(null);
  const [cancelledbookingCheckBox, setCancelledBookingCheckBox] = useState(true);
  const [visible, setVisible] = useState({ status: false, index: 0 });
  const filter = useSelector((state: any) => state.filters.bookingsData);
  const [searchBy, setSearchBy] = useState(
    Object.keys(filter)[0] ?? "unitCode"
  );
  const [search, setSearch] = useState(
    state?.clear == true
      ? ""
      : state?.unitId || filter[Object.keys(filter)[0]] || ""
  );
  const { searchHistory, addToSearchHistory } = useSearchHistory(setFilterBooking);
  useEffect(() => {
    if (selectedProperty?.propertyId)
      dispatch(getBookingUnitList(selectedProperty?.propertyId));
  }, [selectedProperty]);

  useEffect(() => {
    dispatch(clearBookingForm());
    dispatch(getPropertiesDrop());
    dispatch(getBookingPendingReq('bookingExtensionApproval=pending'));
    dispatch(getBookingPendingReq('managerApproval=enable'));
    dispatch(getLeasePendingReq());
    dispatch(getPlanPendingReq());
  }, []);

  function handlePropertyChange(property, label) {
    if (label === "property" && property?.value) {
      setSelectedProperty({
        propertyId: property?.value,
        property_name: property?.label,
      });
      setUpdateValue({ label: "unit", value: null });
      setSelectedUnit({});
    } else if (label === "unit" && property?.value != null) {
      setSelectedUnit(units[property?.value]);
    }
  }

  const sendToTenant = async (email, leaseId) => {
    if (email && leaseId) {
      let payload = {
        emails: [email],
        usertype: "tenant",
        inviteAll: false,
        leaseId: leaseId,
      };
      let successMessage = "Invite sent to tenant successfully";
      const status = await sendTenantInvite(payload, successMessage);
      if (status === 200) {
        const res = await updateBookingStatus(leaseId, {
          status: "tenant_invitation_sent",
        });
        if (res === 200) {
          message.success(successMessage);
          setIsLoading(true)
          dispatch(getBookingForms(limit, currentPage,search, searchBy, getBookingStatuesForFilter(),"",setIsLoading));
        }
      }
    } else {
      message.error("No email id present");
    }
  };

  const BookingForm = () => {
    dispatch(emptyCreateBooking());

    if (selectedUnit?.unitId && selectedProperty)
      navigate("/newbookingform", {
        state: {
          selectedUnit: selectedUnit,
          selectedProperty: selectedProperty,
        },
      });
    else message.error("Please select property and unit");
  };

  //  check booking statuses if all then bookingStatus must be null 
  const getBookingStatuesForFilter = () => {
    if (!bookingStatus || bookingStatus?.value === 'all') {
      return ""
    } else {
      return bookingStatus?.value
    }
  }

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    let cancelleBookingInclude = cancelledbookingCheckBox ? 'yes' : 'no';
    setIsLoading(true)
    dispatch(getBookingForms(limit, pg, search, searchBy, getBookingStatuesForFilter(), cancelleBookingInclude, setIsLoading));
  };

  function onTopSearch(e) {

    // console.log(_.isEmpty(e),'_.isEmpty(e)')

    if (e?.length === 0) setSearch("");
    else if (e?.length < 2 && searchBy !== 'mobileNumber')
      message.error("Please enter atleast two characters");
    else if(searchBy === 'mobileNumber' && e?.length < 3)
      message.error("Please enter atleast three characters");
    else {
      setSearch(e);
    }
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  useEffect(() => {
    setCurrentPage(1);

    addToSearchHistory(searchBy, search); //adding search history and filter store
    let cancelleBookingInclude = cancelledbookingCheckBox ? 'yes' : 'no';
    setIsLoading(true)
    dispatch(getBookingForms(limit, 1, search, searchBy, getBookingStatuesForFilter(), cancelleBookingInclude, setIsLoading));
  }, [search, bookingStatus, cancelledbookingCheckBox]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });

  }, [searchHistory]);

  const onDownload = async (id) => {
    let url = `${baseContractService}/download-booking-form/${id}`;
    await axios
      .get(url)
      .then((response) => {
        if (
          response?.data?.result?.imageUrl !=
          "https://s3.ap-south-1.amazonaws.com/"
        ) {
          window.open(response?.data?.result?.imageUrl);
        } else {
          onDownload(id);
        }
      })
      .catch((e) => {
        const { response } = e;
        message.error(response.data.message);
      });
  };

  const popup = [
    {
      title: (
        <Title level={3} className=" mb-0">
          Booking/Lease Request(s)
        </Title>
      ),
      content: (
        <BookingLeaseRequests
          // showAll={true}
          getAllBookRequests={pendingBookingRequests}
          getAllLeaseRequests={pendingLeaseRequests}
          getAllPlanRequests={pendingPlanRequests}
        />
      ),
      width: 1650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible.status,
    title: popup[visible.index].title,
    content: popup[visible.index].content,
    width: popup[visible.index].width,
    onCancel: () => {
      setVisible({ status: false, index: 0 });
    },
  };

  const MoreAction = (row) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { Text } = Typography;

    const onChnageHanldler = async (e) => {
      if (e === "Cancel") {
        onStatus(row.data.leaseId);
      } else if (e === "Add Unit") {
      } else if (e === "Download booking form") {
      } else if (e === "Send To Tenant") {
        if (row.data?.tenantDetails?.email && row.data?.leaseId)
          sendToTenant(row.data.tenantDetails.email, row.data.leaseId);
      } else if (e === "View Confirmed Form") {
        navigate(`/viewbookingform/${row.data.leaseId}`);
      } else if (e === "View Approved Form") {
        navigate(`/viewbookingform/${row.data.leaseId}`);
      } else if (e === "Edit") {
        if (row?.data?.leaseStatus === "draft")
          navigate(`/newbookingform/${row.data.leaseId}`);
        else navigate(`/viewbookingform/${row.data.leaseId}`);
      } else if (e === "Send for tenant's signature") {
        try {
          let body = { leadId: row.data.id };
          await axios.post(
            `${baseContractService}/booking-form-send-to-prospect`,
            body
          );
          message.success("Successfully Sent");
        } catch (e) {
          const { response } = e;
          message.error(response?.data?.message);
        }
      }
    };
    const onStatus = (leaseId) => {
      PopupConfirm({
        title: `Are you sure you want to cancel`,
        onOk: () => onCancleBookingForm(leaseId),
        okText: "Yes",
      });
    };

    const onCancleBookingForm = async (leaseId) => {
      const res = await updateBookingStatus(leaseId, {
        status: "booking_reject",
      });
      if (res === 200) {
        message.success("Booking has been cancelled");
        setIsLoading(true);
        dispatch(getBookingForms(limit, currentPage, search, searchBy,getBookingStatuesForFilter(),"", setIsLoading));
      }
    };

    return (
      <Select
        onChange={onChnageHanldler}
        className="Sentence"
        placeholder="Select an action"
        style={{ width: 150 }}
        allowClear
      >
        <>
          {row.data.leaseStatus === "booking_created" &&
            (row.data.leaseType == "renewal"
              ? dropDownRenewal.map((value, key) => (
                <React.Fragment key={key}>
                  <Select.Option value={value.name}>
                    <Text style={{ color: `${value.status}` }}>
                      {value.name}
                    </Text>
                  </Select.Option>
                </React.Fragment>
              ))
              : dropDown.map((value, key) => (
                <React.Fragment key={key}>
                  <Select.Option value={value.name}>
                    <Text style={{ color: `${value.status}` }}>
                      {value.name}
                    </Text>
                  </Select.Option>
                </React.Fragment>
              )))}
          {row.data.leaseStatus !== "booking_created" &&
            row.data.leaseStatus !== "booking_confirmed" &&
            row.data.leaseStatus !== "booking_approved" &&
            dropDown1.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus === "booking_confirmed" &&
            dropDown2.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus === "booking_approved" &&
            dropDown3.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
        </>
      </Select>
    );
  };

  const leadCol = [
    {
      headerName: "Status",
      field: "leaseStatus",
      minWidth: 220,
      cellRendererFramework: ({ data }) => (

        <Tag className="tag-sm" color="warning">
          {statusLabel(data.leaseStatus, data?.leaseStatusHistory, bookingStatus)}
        </Tag>
      ),
    },
    {
      headerName: "Booking ID",
      field: "bookingId",
    },
    {
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
      // minWidth: 320,
    },
    {
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
    },
    {
      headerName: "Annual Rent",
      field: "bookingTerms.annualBaseRent",
      minWidth: 350,
      align: "right",
      cellRendererFramework: ({ data }) => {
        return data?.bookingTerms?.annualBaseRent ? (
          <div  style={{position:'absolute',left:'20px'}}>
            {currencyFormat(
              data.bookingTerms?.renewalAmount ||
              data.bookingTerms?.annualBaseRent
            )}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      headerName: "Payments",
      field: "bookingTerms.numberOfInstallments",
    },
    {
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",
      // minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return (
          data?.leaseTerms?.leaseStartDate &&
          dateByformat(data?.leaseTerms?.leaseStartDate, "DD-MM-YYYY")
        );
      },
    },
    {
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
      // minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return (
          data?.leaseTerms?.leaseEndDate &&
          dateByformat(data?.leaseTerms?.leaseEndDate, "DD-MM-YYYY")
        );
      },
    },
    {
      headerName: "Contract Type",
      field: "leaseType",
      cellRendererFramework: ({ data }) => {
        return statusLabel(data?.leaseType);
      },
    },
    {
      headerName: "AWB No",
      field: "shipmentNumber",
    },
    {
      headerName: "Actions",
      field: "manage",
      maxWidth: 200,
      cellRendererFramework: (row) => MoreAction(row),
      pinned: 'right'
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader
                heading="Bookings"
                total={listBooking?.totalCount || 0}
                cancelledBookingCheck={true}
                bookingStatus={true}
                bookingStatusTitle={'Filter By Booking Status'}
                bookingStatuses={bookingStatuses}
                headTopLeftText={
                    pendingBookingRequests?.totalCount ||
                    pendingPlanRequests?.totalCount ||
                    pendingLeaseRequests?.totalCount ? (
                    <Button
                      danger
                      type="text"
                      onClick={() => setVisible({ status: true, index: 0 })}
                    >
                      <InfoCircleOutlined />{" "}
                      {pendingBookingRequests?.totalCount +
                        pendingPlanRequests?.totalCount +
                        pendingLeaseRequests?.totalCount}{" "}
                      pending requests
                    </Button>
                  ) : null
                }
                search={(e) => onTopSearch(e)}
                searchBy={(e) => onTopSearchBy(e)}
                defaultSearch={search}
                searchOptions={searchKeys}
                justifiedItems={true}
                justifyEnd={true}
                handleView={handlePropertyChange}
                updateValue={updateValue}
                viewOptionAction={BookingForm}
                bookingStatusFilter={setBookingStatusFilter}
                bookingStatusFlag={bookingStatus}
                setCancelledBookingCheckBox={setCancelledBookingCheckBox}
                cancelledbookingCheckBox={cancelledbookingCheckBox}
              />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={listBooking?.totalCount || 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} bookings`
                }
                defaultPageSize={50}
                defaultCurrent={1}
                showSizeChanger={false}
                current={currentPage}
                onChange={(e) => handlePagination(e)}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="ag-nospace-body"
                  // extra={<SearchFelds />}
                  >

                    {
                      isLoading ?
                        <LoaderTsx />
                        :

                        <Grid
                          refCol="status"
                          data={listBooking?.data ?? []}
                          columns={leadCol}
                          autoSizeColumns={true}
                          pagination={false}
                          defaultSettings={gridProps}
                          noRowlabel="Bookings"
                          fullPage={true}
                        />
                    }

                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Popup destroyOnClose={true} {...popupProps} />
    </DashboardLayout>
  );
};

export default BookingListing;
