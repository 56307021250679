import { Button, Drawer, Space } from 'antd';
import React from 'react';



/**
* @author
* @function @
**/

export default React.memo((props: any) => {

    return (
        <Drawer
            mask={props?.mask}
            title={props?.title}
            placement={props?.placement}
            width={props?.width ? props?.width : '378px'}
            closable={true}
            onClose={() => props?.closeDrawer(!props?.openDrawer)}
            open={props?.openDrawer}
            footer={
                <div
                  style={{
                    textAlign: "right",
                    padding:'20px'
                  }}
                >
                </div>
                    }
        >
            {props?.children}
        </Drawer>
    )
});
