import * as action_types from "./constants";

const initialState: any = {
  ticketsType: [],
  ticketsSubType: [],
  propertyname: [],
  eligibletechnicians: [],
  availableSlots: [],

  ticketsDataNew: null,
  ticketsDataProgress: null,
  ticketsDataClosed: null,
  ticketsDataAcknowledged: null,

  ticketsCountNew: 0,
  ticketsCountProgress: 0,
  ticketsCountClosed: 0,

  getDeviceToken: "",
  getPresignedImage: "",
  getTicketExport: [],
  orgData: {}
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.TICKETS_COUNT_NEW:
      return { ...state, ticketsCountNew: data };
    case action_types.TICKETS_COUNT_PROGRESS:
      return { ...state, ticketsCountProgress: data };
    case action_types.TICKETS_COUNT_CLOSED:
      return { ...state, ticketsCountClosed: data };
    case action_types.TICKETS_ACKNOWLEDGED:
      return { ...state, ticketsDataAcknowledged: data };
    case action_types.TICKETS_PROGRESS:
      return { ...state, ticketsDataProgress: data };
    case action_types.TICKETS_CLOSED:
      return { ...state, ticketsDataClosed: data };
    case action_types.TICKETS_NEW:
      return { ...state, ticketsDataNew: data };
    case action_types.TICKETS_TYPE:
      return { ...state, ticketsType: data };
    case action_types.TICKETS_SUB_TYPE:
      return { ...state, ticketsSubType: data };
    case action_types.PROPERTY_NAME:
      return { ...state, propertyname: data };
    case action_types.ELIGIBLE_TECHNICIAN:
      return { ...state, eligibletechnicians: data };
    case action_types.AVAILABLE_SLOTS:
      return { ...state, availableSlots: data };
    case action_types.GET_DEVICEID_TOKEN:
      return { ...state, getDeviceToken: data };
    case action_types.PRESIGNED_IMAGE:
      return { ...state, getPresignedImage: data };
    case action_types.RESET_TECHNICIAN:
      return { ...state, eligibletechnicians: data };
    case action_types.TICKETS_NEW_STATUS_TYPE:
      return { ...state, newTicketStatusType: data };
    case action_types.TICKETS_CLOSED_STATUS_TYPE:
      return { ...state, closedTicketStatusType: data };
    case action_types.TICKETS_PROGRESS_STATUS_TYPE:
      return { ...state, progressTicketStatusType: data };
    case action_types.TICKETS_REVIEWS:
      return { ...state, getTicketReviews: data };
    case action_types.TICKET_BY_ID:
      return { ...state, getTicketById: data };
    case action_types.TICKETS_EXPORT:
      return { ...state, getTicketExport: data };
    case action_types.GET_PROPERTY_UNITS:
      return { ...state, fetchPropertyUnits: data };
    case action_types.ORG_BY_ID:
      return { ...state, orgData: data };
  
    default:
      return state;
  }
};
