import React, { useMemo } from "react";

// others

import { Col, Row, Spin } from "antd";
import { calculatePaidAmountAndDueDate } from "../../BookingForm/components/utils";
import { Item } from "../../BookingForm/components/view";
import { numberWithCommas } from "../../BookingForm/components/utils";
import { apiDateFormat } from "../../BookingForm/components/utils";
interface IProps {
    booking: any;
    kycDetails?: Array<object>;
}

const TerminationDetail = (props: IProps) => {
    // variables

    const isBooking = props?.booking && Object.keys(props.booking).length > 0;
    const _ = require('lodash');
    const { totalPaidAmount, lastPaidDate, securityDepositAmount, securityDepositDate } = useMemo(() => calculatePaidAmountAndDueDate(props.booking?.paymentSchedule), [props?.booking?.actualRentDue]);
    

// for caculate finalSettlementAmount

 const settlementAmount:any = useMemo(() => {
    
 
    let diffBtwRentDueAndRentReceived: any = props?.booking?.actualRentDue ? parseFloat(props.booking.actualRentDue) : 0 - totalPaidAmount;

    let terminationAndPenaltySum: any = parseFloat(props?.booking?.termination?.totalterminationPaneltyAmount || 0) + parseFloat(props?.booking?.overstay?.totalOverStayPaneltyAmount || 0);

    let clientFee =  parseFloat(props?.booking?.settlementSummary?.deduction || 0)  + securityDepositAmount + totalPaidAmount;

    let adminFee = parseFloat(diffBtwRentDueAndRentReceived || 0 ) 
                  + parseFloat(terminationAndPenaltySum || 0) 
                  + parseFloat(props?.booking?.settlementSummary?.maintenancefee || 0)
                  + parseFloat(props?.booking?.settlementSummary?.otheradminfee || 0)
                  + parseFloat(props?.booking?.settlementSummary?.refurbismentfee || 0 )

    return (adminFee - clientFee)?.toFixed(2);
  
}, [props?.booking?.actualRentDue]);

    const format = "DD-MMM-YYYY";
  
    return (
        <Col span={24} className="view-booking">
            {!isBooking && (
                <Row gutter={24} className="loading">
                    <Spin size="large" />
                </Row>
            )}

            {isBooking && (

                <>
                    <Row gutter={24} className="bd-item">
                        <Item
                            label="Penalty Type"
                            value={_.capitalize(props?.booking?.moveOutType)}

                        />
                        <Item
                            renewalAmout={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && apiDateFormat(props?.booking?.oldSettlementSummary?.vacatingDate,format)}
                            label="Move-out Date"
                            value={props?.booking?.vacatingDate ? apiDateFormat(props?.booking?.vacatingDate,format) : '-'}
                            settlementSummary={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && apiDateFormat(props?.booking?.settlementSummary?.props?.booking?.vacatingDate,format)}
                        />
                        <Item
                            label="Move-out Inspection Date"
                            value={props?.booking?.moveOutInspection ? apiDateFormat(props?.booking?.moveOutInspection["date"],format) : '-'}

                        />
                        <Item
                            label="Lease Term"
                            value={`${props?.booking?.leaseTermDays ? props?.booking?.leaseTermDays : 0} days |
                                    AED ${props?.booking?.leaseTerms?.contractAmount ? (props?.booking?.leaseTerms?.contractAmount) : 0}`}
                        />

                        <Item
                            label="Days Occupied"
                            value={`${props?.booking?.daysOccupied ? props?.booking?.daysOccupied : 0} Days`}
                        />

                        <Item
                            label="Rent Per Day"
                            value={`AED ${props?.booking?.rentPerDay ? (numberWithCommas(props?.booking?.rentPerDay)) : 0}`}
                        />

                        <Item
                            label="Security Deposit"
                            value={`${securityDepositDate} | AED ${(securityDepositAmount)}`}
                        />
                        <Item
                            label="Rent Received Until "
                            value={`${lastPaidDate}  | AED ${numberWithCommas(totalPaidAmount)}`}
                        />
                        <Item
                            label="Actual Rent Due"
                            value={`${props?.booking?.daysOccupied ? props?.booking?.daysOccupied : 0} days | AED ${props?.booking?.actualRentDue ? numberWithCommas(props?.booking?.actualRentDue) : 0}`}
                        />
                        <Item
                            renewalAmout={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.oldSettlementSummary?.maintenancefee }
                            label="Maintenance Fee"
                            settlementSummary={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.maintenancefee }
                            value={`AED ${props?.booking?.settlementSummary?.maintenancefee ? numberWithCommas(props?.booking?.settlementSummary?.maintenancefee) : 0}`}
                        />
                        <Item
                            renewalAmout={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.oldSettlementSummary?.refurbismentfee }
                            label="Refurbishment Fee"
                            value={`AED ${props?.booking?.settlementSummary?.refurbismentfee ? numberWithCommas(props?.booking?.settlementSummary?.refurbismentfee) : 0}`}
                            settlementSummary={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.refurbismentfee } 
                        />

                        <Item
                            renewalAmout={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.oldSettlementSummary?.otheradminfee}
                            label="Other / Admin Fee"
                            value={`AED ${props?.booking?.settlementSummary?.otheradminfee ? numberWithCommas(props?.booking?.settlementSummary?.otheradminfee) : 0} `}
                            settlementSummary={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.otheradminfee }
                        />

                        <Item

                            label="Overstay"
                            value={`${props?.booking?.overstay?.numberOfDays ? props?.booking?.overstay?.numberOfDays : 0} days | AED ${props?.booking?.overstay?.totalOverStayPaneltyAmount ? Number(props?.booking?.overstay?.totalOverStayPaneltyAmount) : 0}`}
                        />

                        <Item
                            label="Termination Charge"
                            value={`AED ${props?.booking?.termination?.totalterminationPaneltyAmount ? numberWithCommas(props?.booking?.termination?.totalterminationPaneltyAmount) : 0}`}
                        />

                        <Item 
                            renewalAmout={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.oldSettlementSummary?.deduction}
                            label="Deduction / Waiver"
                            value={`AED ${props?.booking?.settlementSummary?.deduction ? numberWithCommas(props?.booking?.settlementSummary?.deduction) : 0} `}
                            settlementSummary={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.deduction}
                        />

                        <Item
                            renewalAmout={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.oldSettlementSummary?.settlementAmount}
                            label="Settlement Amount"
                            value={`AED ${settlementAmount ? numberWithCommas(settlementAmount): 0} `}
                            settlementSummary={(props?.booking?.oldSettlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.settlementAmount}
                        />



                    </Row>

                </>
            )}
        </Col>
    );
};

export default TerminationDetail;
