import React, { useState, useEffect, useMemo, useRef } from "react";
import moment from "moment";
// others
import { Loading } from "./index";
import Button from "../../../../molecules/Button";
import { DownloadOutlined } from "@ant-design/icons";
import PdfView from "../../../../molecules/PDFViewer/pdvViewNew";
import { Card, Row, Col, Form, message, Image, Upload, Space, Radio, Switch, Tag, Tooltip, Spin } from "antd";
import { useForm, useWatch } from "react-hook-form";
// import { Col, Row, Form, Space, message ,Upload} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { dateBookingDDMMYYFormat } from "../utils";
import { calculatePaidAmountAndDueDate } from "../utils";
import { uploadDocumentMedia, getPresignedImage } from "../../../../../utils/media";
import {
  updateBooking,
  getBookingDetail,
} from "../../ducks/actions";
import { numberWithCommas } from "../utils";
import {
  DateField,
  InputField,
  ReactSelectField,
  InputCheckbox,
} from "../../../../atoms/FormElement";
import {
  datePlaceholderddmmyyFormat,
  dateFormat
} from "../utils";

interface IProps {
  renewal?: boolean;
  parentState: (obj: object) => void;
}

let initialState = {
  visible: false,
  authorizedSignatory: "",
};

var _ = require("lodash");


import { ExclamationCircleFilled, LinkOutlined, UploadOutlined } from "@ant-design/icons";
import { getMoveOutDetailService } from "../../ducks/services";

const index = (props: IProps) => {
  // hooks initialization
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  const {bookingDetails} = useSelector((state: any) => state.newBooking);
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState(null);
  const [openFileView, setOpenFileView] = useState(false);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();


  // states

  const [{ visible }, setState] = useState(initialState);
  const [uploadedDocData, setUploadedDocData] = useState(null);


  const updateState = (state: {}) => setState((prevState: any) => ({ ...prevState, ...state }));
  const [moveOutDetail, setMoveOutDetail] = useState(null);

  const parseCurrency = (input) => {
    if (input) {
      // Remove all non-numeric characters except the decimal point
      const numericValue = input.replace(/[^0-9.]/g, "");
  
      // Ensure there's only one valid decimal point
      const validNumericValue = numericValue.includes(".")
        ? numericValue.split(".").slice(0, 2).join(".") // Keep only the first valid decimal
        : numericValue;
      // Parse the resulting string as a float
      return parseFloat(validNumericValue || "0");
    } else {
      if(_.isEmpty(input)){

        return null;
      }else{
        return 0;
      }
     
    }
  };
  
  const onSubmit = async (data: any, type) => {

    if(data?.moveOutConfirm === true || bookingDetails?.moveOutInspection && bookingDetails?.moveOutInspection["date"]){
      updateState({ visible: true });
      try {
      
        let dataCopy = { ...(data || getValues()) };
        let body = {
          "settlementSummary": {
            "maintenancefee": parseCurrency(dataCopy?.maintenanceFee),
            "refurbismentfee": parseCurrency(dataCopy?.refurbismentFee),
            "otheradminfee": parseCurrency(dataCopy?.adminFee),
            "deduction": parseCurrency(dataCopy?.waiver),
            "settlementAmount": parseFloat(howShowFinalAmount())?.toFixed(2)
          },
          "moveOutInspection": {
            "date": dataCopy?.moveOutInspectionDate ?
              moment(dataCopy?.moveOutInspectionDate.$d).format("YYYY-MM-DD") : null,
            "documentId": uploadedDocData?.documentId ? uploadedDocData?.documentId : null
          },
          "vacatingDate": dataCopy?.moveOutDate ? moment(dataCopy?.moveOutDate.$d).format("YYYY-MM-DD") : null,
          "settlementApproval": type !==1 ?  "yes" : "no",
          onlyBooking: true
  
        }
        
      
        const response = await dispatch(
          updateBooking({ leaseId, body: body })
        );
  
  
        if (response?.data?.code === 200) {
          dispatch(getBookingDetail(leaseId+`?moveout=yes`));
          message.success(response.data.message);
          updateState({ visible: false });
         props.parentState({
            modalPayload: { iKey: "", title: "", extraText: "", open: false },
          });
        }
  
      } catch (error) {
        updateState({ visible: false });
        props.parentState({ visible: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }else{
      message.error('please mark complete before submit')
      return false
    }
 
  };

  const penaltyOption = [
    {
      label: 'Early',
      value: 'early',
    },
    {
      label: 'Late',
      value: 'late',
    },
    {
      label: 'Natural',
      value: 'natural',
    },
  ]

  const { totalPaidAmount, lastPaidDate, securityDepositAmount, securityDepositDate } = useMemo(() => calculatePaidAmountAndDueDate(moveOutDetail?.paymentSchedule), [moveOutDetail?.actualRentDue]);


  const fileProps = {
    className: "file-upload",
    name: "file",
    accept: '.jpg,.png,.jpeg,.pdf',
    multiple: false,
    showUploadList: false,
    onChange(info){

      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only image file`);
      }
    },

    beforeUpload: async (file) => {

      try {
        let uploadConfirmation = await uploadDocumentMedia({ file_asset: file });
        if (uploadConfirmation?.data?.code === 200) {
          message.success(`file uploaded successfully.`);
          setUploadedDocData(uploadConfirmation?.data?.result);
         
        }

        return false
      } catch ({ response }) {
        message.error(response?.data?.error);
      }

    },
  };

   
  const preViewDoc = async (docId) => {

    let documentId = uploadedDocData?.documentId ? uploadedDocData?.documentId : docId;
    const url = await getPresignedImage([documentId]);
    if (url && url.length > 0) {
      window.open(url[0], '_blank');
    }
  }


  let diffBtwRentDueAndRentReceived = moveOutDetail?.actualRentDue ? moveOutDetail?.actualRentDue  : 0 - totalPaidAmount;
  let terminationAndPenaltySum = moveOutDetail?.termination?.totalterminationPaneltyAmount + moveOutDetail?.overstay?.totalOverStayPaneltyAmount;

  let [vacatingDate,seVacatingDate] = useState(bookingDetails?.vacatingDate);
  const [adminSidePaid, setAdminSidePaid] = useState(0);
  const [tenantSidePaid, setTenantSidePaid] = useState(0);
  const [maintenanceCharges, setMaintenanceCharges] = useState(0);
  const [refurbismentCharges, setRefurbismentCharges] = useState( 0);
  const [adminCharges, setAdminCharges] = useState( 0);
  const [deductionCharges, setDeductionCharges] = useState(0);


  const howShowFinalAmount = () => {
    
    return (adminSidePaid - tenantSidePaid)?.toFixed(2);
    
  }

  const getAdminPaidCharges = () => {
    
    return maintenanceCharges + diffBtwRentDueAndRentReceived + refurbismentCharges + adminCharges + terminationAndPenaltySum;
  }

  const setFinalAmount = (value, type) => {

     
    if (type === 1) {
    
      setMaintenanceCharges(parseCurrency(value?.target?.value));
    } else if (type === 2) {
      setRefurbismentCharges(parseCurrency(value?.target?.value));
    } else if (type === 3) {

      setAdminCharges(parseCurrency(value?.target?.value));
    } else if (type === 4) {
      setDeductionCharges(parseCurrency(value?.target?.value))

    }


  }


  const getMoveOutDetail = async  ()=>{
   
    let response = await getMoveOutDetailService(leaseId,vacatingDate?.slice(0,10));
    if(response){
      response?.vacatingDat && setValue('moveOutDate',response?.vacatingDate);
      maintenanceCharges === 0 && setMaintenanceCharges(response?.settlementSummary?.maintenancefee ? response?.settlementSummary?.maintenancefee : 0);
      refurbismentCharges === 0 && setRefurbismentCharges(response?.settlementSummary?.refurbismentfee ? response?.settlementSummary?.refurbismentfee : 0);
      adminCharges === 0 && setAdminCharges(response?.settlementSummary?.otheradminfee ? response?.settlementSummary?.otheradminfee	 : 0);
      deductionCharges === 0 && setDeductionCharges(response?.settlementSummary?.deduction ? response?.settlementSummary?.deduction : 0);
      setAdminSidePaid(getAdminPaidCharges());
      setMoveOutDetail(response);
    }

  }

  useEffect(() => {
    setAdminSidePaid(getAdminPaidCharges());
    setTenantSidePaid(deductionCharges + securityDepositAmount + totalPaidAmount);
  }, [maintenanceCharges, refurbismentCharges, adminCharges, deductionCharges,moveOutDetail?.actualRentDue]);

  
  useEffect(() => {
    getMoveOutDetail();
  }, [vacatingDate]);

  
  const TooltipContent = ({ data, type }) => {

    
    if(type === 1  ){
      return (
        <div>
          {/* totalOverStayPaneltyAmount	2040 type	*/}
          <span>Penalty type = {data?.type}</span><br/>
          <span>Amount per day = {data?.amount}</span><br/>
          <span>VAT = {data?.vat}</span><br/>
          <span>Overstay days = {data?.numberOfDays}</span><br/> 
          <span>Overstay penalty vat = {`(OverStay days)% * (Amount per day) = ${numberWithCommas(data?.overStayPaneltyVatAmount)}`}</span><br/>
        { 
        data?.type?.toLowerCase() === 'amount' ? 
        <><span>Total overstay penalty = {`(Overstay days  * Amount per day) + VAT % = ${numberWithCommas(data?.totalOverStayPaneltyAmount)}`}</span><br /></>
        :
        <><span>Total overstay penalty = {`((Rent per day * overstayPenaltyAmount)/100) * Amount per day + VAT %  = ${numberWithCommas(data?.totalOverStayPaneltyAmount)}`}</span><br /></>
      }
        </div>
      );
    }else{
      return (
        <div>
        
          <span>Penalty type = {data?.type}</span><br/>
        {     
     
         
        <> <span>Penalty amount= {data?.amount}</span><br/></>
       
        
         }
          <span>VAT = {data?.vat}</span><br/>
          <span>Number of days = {data?.numberOfDays}</span><br/> 
      { 
          data?.type?.toLowerCase() === 'amount' 
          ?
          <><span>Termination panelty amount = {`(Number of days * Penalty amount) =  ${numberWithCommas(data?.terminationPaneltyAmount)}`}</span><br/></>
        :
        <><span>Termination panelty amount = {`(Amount per month * Penalty amount) =  ${numberWithCommas(data?.terminationPaneltyAmount)}`}</span><br/></>

        }
          <span>Penalty vat amount = {`(termination panelty amount * VAT) / 100 = ${numberWithCommas(data?.terminationPaneltyVatAmount)}`}</span><br/>

          <><span>Total Termination penalty = {`(Termination panelty amount * Penalty vat amount) = ${numberWithCommas(data?.totalterminationPaneltyAmount)}`}</span><br /></>
       
        </div>
      );
    }
    
  };
  const isBooking = moveOutDetail && Object.keys(moveOutDetail).length > 0;
  

  return (
 
    <>
     {
      (!isBooking ) && 
      <Row gutter={24} className="loading">
         <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'500px',width:'100%'}}>
         <Spin size="large" />
         </div>
      </Row>
      }

     {   

(isBooking) &&
 <>
  <PdfView
        pdfData={viewOrDownloadUrl}
        openFileView={openFileView}
        setOpenFileView={setOpenFileView}
      />
      <Form
        layout="vertical"
        name="bookingform"
        scrollToFirstError
        className="bf-main"
        onFinish={handleSubmit(onSubmit)}
      >
        <Loading visible={visible} />

        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <ReactSelectField
                control={control}
                setValue={setValue}
                label="Penalty Type"
                fieldname="penaltyType"
                selectOption={penaltyOption}
                iProps={{ placeholder: "Select", isDisabled: true }}
                rules={{ required: "Please select one" }}
                validate={errors.penaltyType && "error"}
                validMessage={errors.penaltyType && errors.penaltyType.message}
                valueGot={penaltyOption?.find((item) => item?.value === moveOutDetail?.moveOutType?.toLowerCase())}
              />
            </Col>

            <Col span={8}>


              <DateField
                control={control}
                setValue={setValue}   
                label="Move-out Date"
                fieldname="moveOutDate"
                valueGot={dateBookingDDMMYYFormat(vacatingDate)}
                onChange={(e)=>seVacatingDate('20'+moment(e?.$d).format("YY-MM-DD"))}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                }}
              />

            </Col>

            <Col span={7}>

              <div style={{ position: "absolute", right: '14px', top: '-10px' }}>
                <InputCheckbox
                  fieldname="moveOutConfirm"
                  label="Complete"
                  control={control}
                  classes={`prm-checkbox`}
                  onChange = {()=>console.log()}
                  valueGot={(bookingDetails?.moveOutInspection && bookingDetails?.moveOutInspection["date"] )? true : ''}
                  iProps={{
                    className: `prm-checkbox`,
                  }}
                  setValue={setValue}
                />
                {
                  (uploadedDocData?.documentId || (bookingDetails["moveOutInspection"] && bookingDetails?.moveOutInspection['documentId'])) &&
                  <Tooltip title="View" trigger="hover" showArrow={false}>

                    <Button
                      title={'View'}
                      label={<DownloadOutlined  />}
                      type="button"
                      style={{ marginTop: '25px',zIndex:999,top:'-43px',left:'114px' }}
                      className="ef-action-btn"
                      onClick={() => preViewDoc(bookingDetails["moveOutInspection"] && (bookingDetails?.moveOutInspection['documentId'] ? bookingDetails?.moveOutInspection['documentId'] : uploadedDocData?.documentId))}
                      myClassSet={true}

                    />

                  </Tooltip>
                }
              </div>

              <DateField
                control={control}
                setValue={setValue}
                label="Move-out Inspection Date"
                fieldname="moveOutInspectionDate"
                valueGot={dateBookingDDMMYYFormat(
                  bookingDetails?.moveOutInspection && bookingDetails?.moveOutInspection["date"]
                )}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                }}
              />

            </Col>

            <Col span={1}>

              <Space size={20}>
                <Upload  {...fileProps}>
                  <span>
                    {

                      ((bookingDetails["moveOutInspection"] && !bookingDetails?.moveOutInspection['documentId']) || !uploadedDocData?.documentId)
                        ?
                        <LinkOutlined style={{ marginTop: '30px', fontSize: '25px' }} />
                        :
                        <Tooltip title="Upload" trigger="hover" showArrow={false}>

                          <Button
                            title={'upload'}
                            label={<UploadOutlined />}
                            type="button"
                            style={{ marginTop: '25px' }}
                            className="ef-action-btn"
                            myClassSet={true}

                          />

                        </Tooltip>

                    }


                  </span>
                </Upload>


              </Space>


            </Col>

            <Col span={8}>
              <InputField
                control={control}
                disabled={true}
                setValue={setValue}
                label="Lease Term"
                fieldname="leaseTerm"
                valueGot={
                  `${moveOutDetail?.leaseTermDays ? moveOutDetail?.leaseTermDays : 0} days | AED ${moveOutDetail?.leaseTerms?.contractAmount ? moveOutDetail?.leaseTerms?.contractAmount : 0}`
                }
                validate={errors.leaseTerm && "error"}
                validMessage={
                  errors.leaseTerm && errors.leaseTerm.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                disabled={true}
                label="Days Occupied"
                fieldname="daysOccupied"
                valueGot={`${moveOutDetail?.daysOccupied ? moveOutDetail?.daysOccupied : 0} days`}
                validate={errors.daysOccupied && "error"}
                validMessage={
                  errors.daysOccupied && errors.daysOccupied.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                disabled={true}
                label="Rent Per Day"
                disable={true}
                fieldname="rentPerDay"
                valueGot={`AED ${moveOutDetail?.rentPerDay ? numberWithCommas(moveOutDetail?.rentPerDay) : 0}`}
                validate={errors.rentPerDay && "error"}
                validMessage={
                  errors.rentPerDay && errors.rentPerDay.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                disabled={true}
                label="Security Deposit"
                fieldname="securityDeposit"
                valueGot={`${securityDepositDate} | AED ${numberWithCommas(securityDepositAmount)}`}
                validate={errors.securityDeposit && "error"}
                validMessage={
                  errors.securityDeposit && errors.securityDeposit.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                disabled={true}
                label="Rent Received Until"
                fieldname="rentReceived"
                valueGot={`${lastPaidDate} | AED ${numberWithCommas(totalPaidAmount)}`}
                validate={errors.rentReceived && "error"}
                validMessage={
                  errors.rentReceived && errors.rentReceived.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                disabled={true}
                label="Actual Rent Due"
                fieldname="rentDue"
                valueGot={`${moveOutDetail?.daysOccupied ? moveOutDetail?.daysOccupied : 0} days | AED ${moveOutDetail?.actualRentDue ? numberWithCommas(moveOutDetail?.actualRentDue) : 0}`}
                validate={errors.rentDue && "error"}
                validMessage={
                  errors.rentDue && errors.rentDue.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                onChange={(e) => setFinalAmount(e, 1)}
                label="Maintenance Fee (if any)"
                fieldname="maintenanceFee"
                valueGot={`AED ${moveOutDetail?.settlementSummary?.maintenancefee ? numberWithCommas(moveOutDetail?.settlementSummary?.maintenancefee):0} `}
                validate={errors.maintenanceFee && "error"}
                validMessage={
                  errors.maintenanceFee && errors.maintenanceFee.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                label="Refurbisment Fee (if any)"
                fieldname="refurbismentFee"
                onChange={(e) => setFinalAmount(e, 2)}
                valueGot={`AED ${moveOutDetail?.settlementSummary?.refurbismentfee ? numberWithCommas(moveOutDetail?.settlementSummary?.refurbismentfee) : 0} `}
             
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                label="Other / Admin Fee (if any)"
                fieldname="adminFee"
                onChange={(e) => setFinalAmount(e, 3)}
                valueGot={`AED ${moveOutDetail?.settlementSummary?.otheradminfee ? numberWithCommas(moveOutDetail?.settlementSummary?.otheradminfee) : 0} `}
             
              />
            </Col>

            <Col span={8}>

              <div style={{ position: "absolute", right: '14px', zIndex: 999 }}>
                <Tooltip placement="top"
                  overlayInnerStyle={{ backgroundColor: "#030218", color: "#ffffff", width:'450px' }} // Custom styles
                  title={<TooltipContent data={moveOutDetail?.overstay} type={1}  />}>
                  <ExclamationCircleFilled title={moveOutDetail?.overstay?.overStayPaneltyFrom} />
                </Tooltip>
              </div>
              <InputField
                control={control}
                setValue={setValue}
                label="OverStay"
                disabled={true}
                fieldname="Overstay"
                valueGot={`${moveOutDetail?.overstay?.numberOfDays ? moveOutDetail?.overstay?.numberOfDays : 0} days | AED ${moveOutDetail?.overstay?.totalOverStayPaneltyAmount ? numberWithCommas(moveOutDetail?.overstay?.totalOverStayPaneltyAmount ): 0}`}
              
              />
            </Col>

            <Col span={8}>
              <div style={{ position: "absolute", right: '14px', zIndex: 9999 }}>

                <Tooltip
                  placement="top" 
                  overlayInnerStyle={{ backgroundColor: "#030218", color: "#ffffff", width:'500px' }} // Custom styles
                  title={<TooltipContent data={moveOutDetail?.termination} type={2}  />} >
                  <ExclamationCircleFilled title={moveOutDetail?.termination?.terminationPaneltyFrom} />
                </Tooltip>

              </div>
              <InputField
                control={control}
                disabled={true}
                setValue={setValue}
                label="Termination Charges"
                fieldname="terminationCharges"
                valueGot={`AED ${moveOutDetail?.termination?.totalterminationPaneltyAmount ? numberWithCommas(moveOutDetail?.termination?.totalterminationPaneltyAmount ): 0}`}
                validate={errors.terminationCharges && "error"}
                validMessage={
                  errors.terminationCharges && errors.terminationCharges.message
                }
              />
            </Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                label="Deduction / Waiver"
                fieldname="waiver"
                valueGot={`AED ${moveOutDetail?.settlementSummary?.deduction ? numberWithCommas(moveOutDetail?.settlementSummary?.deduction) : 0} `}
                onChange={(e) => setFinalAmount(e, 4)}
              />
            </Col>

            <Col span={8}></Col>
            <Col span={8}></Col>

            <Col span={8}>
              <InputField
                control={control}
                setValue={setValue}
                disabled={true}
                label="Settlement Amount"
                fieldname="settlementAmount"
                valueGot={`AED ${numberWithCommas(parseFloat(howShowFinalAmount()))}`}
              />
            </Col>




          </Row>

          <Row gutter={24} className="footer">
            <Space size="middle">
              <Button
                label="Cancel"
                className="bf-discard-button"
                onClick={() =>
                  props.parentState({ modalPayload: { open: false } })
                }
              />

              <Button
                type="submit"
              
                label="Save"
                className="cc-fotter_next-button"
              />
            </Space>
          </Row>
        </Col>

      </Form>
     
     </>
     }
    </>
  );
};

export default React.memo(index);