import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { managementService } from "../../../../configs/constants";

export const getManagers = (id, limit, page, search, searchBy) => {
  return async (dispatch) => {
    let managers = "";
    let url = "";
    managers = `${managementService}/landlord/user`;
    if(search){
      url = `${managers}?page=${page}&name=${search}`;
    }else{
      url = `${managers}?page=${page}`;
    }

    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_MANAGERS,
      data: result,
    });
  };
};

export const getMasterManagers = () => {
  return async (dispatch) => {
    let url = "";
    url = `${managementService}/masterUser`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_ALL_MANAGERS,
      data: result,
    });
  };
};


export const emptyManagers = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_MANAGERS,
      data: {
        count: 0,
        results: [],
      },
    });
  };
};

