import React, { useLayoutEffect } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Divider,
    Space,
    message,
} from "antd";
import { useForm } from "react-hook-form";

import { DateField, InputField } from "../../../../atoms/FormElement";
import { dateFormat, datePlaceholderddmmyyFormat, disableBookingEndDate } from '../../../BookingForm/components/utils';



export default React.memo((props: any) => {

    const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, setError, clearErrors } = useForm({});
    const [screenSize, setScreenSize] = React?.useState('');

    useLayoutEffect(() => {
        const checkScreenSize = () => {
            const width = window.innerWidth;
            if (width >= 1500) {
                setScreenSize('xl');

            } else if (width >= 992) {
                setScreenSize('lg');
            } else {
                setScreenSize('smaller than lg');
            }
        };

        // Initial check
        checkScreenSize();
        // Add event listener to update on resize
        window.addEventListener('resize', checkScreenSize);
        // Clean up the event listener on unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);


    const {

        setDateTo,
        setDateFrom,
        setPaidDateTo,
        setPaidDateFrom,
        setPaymentRefFilter,
        setTenantNameFilter,
        setBankRefFilter,
        paidDateFrom,
        paidDateTo,
        filterReset
    } = props;


    const onFilterSubmit = (e) => {


        if (screenSize === 'lg') {
             setDateFrom(e.dueDateFrom === "" ? null : e.dueDateFrom);
             setDateTo(e.dueDateTo  === "" ? null :   e.dueDateTo);
        }
        setPaidDateTo(  e?.paidDateTo === "" ? null : e?.paidDateTo);
        setPaidDateFrom(e?.paidDateFrom === "" ? null : e?.paidDateFrom);
        setBankRefFilter(e?.bankRef === "" ? null : e?.bankRef);
        setPaymentRefFilter(e?.paymentRef === "" ? null :   e?.paymentRef);
        setTenantNameFilter(e?.tenantName === "" ? null :  e?.tenantName);

    }

    const resetAllFields = () => {

        setValue('paidDateFrom', null);
        setValue('paidDateTo', null);
        setValue('paymentRef', null);
        setValue('tenantName', null);
        setValue('bankRef', null);
        filterReset();
    }

    return (
        <Form
            layout="vertical"
            className="bf-main"
            onFinish={handleSubmit((e) => onFilterSubmit(e))}
        >

            <Row gutter={6}>


                <Col span={12}>
                    <DateField
                        control={control}

                        setValue={setValue}
                        label="Paid Date From"
                        fieldname="paidDateFrom"
                        iProps={{
                            format: datePlaceholderddmmyyFormat,
                            placeholder: "Date From",
                        }}
                        valueGot={paidDateFrom}
                    />
                </Col>


                <Col span={12}>
                    <DateField
                        control={control}

                        setValue={setValue}
                        label="Paid Date To"
                        fieldname="paidDateTo"
                        iProps={{
                            format: datePlaceholderddmmyyFormat,
                            placeholder: "Date To",
                        }}
                        valueGot={paidDateTo}
                    />
                </Col>

                <Col span={24}>
                    <InputField
                        fieldname="paymentRef"
                        label={"Payment Ref"}
                        control={control}
                        iProps={{
                            placeholder: "Payment Ref",

                        }}
                        initValue=""
                    />
                </Col>

                <Col span={24}>
                    <InputField
                        fieldname="bankRef"
                        label={"Bank"}
                        control={control}
                        iProps={{
                            placeholder: "Bank",

                        }}
                        initValue=""
                    />
                </Col>

                <Col span={24}>
                    <InputField
                        fieldname="tenantName"
                        label={"Tenant Name"}
                        control={control}
                        iProps={{
                            placeholder: "Tenant Name",

                        }}
                        initValue=""
                    />
                </Col>
                <Col span={24}>
             
                    <Space  style={{position:'fixed',bottom:'10px',right:'5px'}}>
                        <Button size={'small'} style={{ marginTop: '10px' }} className="ef-approve-btn" htmlType="submit">
                            {'Submit'}
                        </Button>

                        <Button  size={'small'} style={{ marginTop: '10px' }} onClick={() => resetAllFields()} className="ef-reject-btn" htmlType="button">
                            {'Reset'}
                        </Button>
                    </Space>


                </Col>


            </Row>

        </Form>
    )
})

