import * as React from "react";
import { useEffect, useLayoutEffect } from "react";

import {
  Row,
  Col,
  Space,
  Typography,
  Form,
  Select,
  Divider,
  Button,
  Dropdown,
  DatePicker,
  Grid,
  message,
  Input
} from "antd";
import moment from 'moment';
import {
  PlusOutlined,
  FilterOutlined,
  DownloadOutlined,
  ExportOutlined,
  SearchOutlined,
} from "@ant-design/icons";
const { Title, Text } = Typography;
import grid from "../SectionHeader/grid.svg";
import list from "../SectionHeader/list.svg";
import Search from "antd/es/input/Search";
import {
  DateField,
  ReactSelectField,
  InputAutocompleteField,
} from "../../atoms/FormElement";
import BookingStatusFilter from "../../modules/BookingListing/BookingStatusFilter";
import { Controller, useForm } from "react-hook-form";


export default React.memo((props: any) => {

  // Function to disable future dates
  const disableFutureDates = (current) => {
    // Disable dates after today
    return current && current > moment().endOf('day');
  };
  const [screenSize, setScreenSize] = React?.useState('');

  useLayoutEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      if (width >= 1500) {
        setScreenSize('xl');

      } else if (width >= 992) {
        setScreenSize('lg');
      } else {
        setScreenSize('smaller than lg');
      }
    };

    // Initial check
    checkScreenSize();
    // Add event listener to update on resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);


  const {
    heading,
    total,
    importAction,
    filterAction,
    addNewAction,
    downloadAction,
    dropNewAction,
    dropItems,
    dropTrigger,
    addMoreButton,
    exportAction,
    bookingStatuses,
    cancelledBookingCheck,
    handleView,
    viewOption,
    viewOptionLabel,
    viewOptionIcon,
    viewOptionAction,
    search,
    searchOptions,
    searchName,
    searchLabel,
    defaultSearch,
    headReverse,
    viewRSelectOption,
    initialValue,
    onChange,
    justifiedItems,
    justifyEnd,
    bottom,
    right,
    justifyStart,
    dateAction,
    initialDate,
    headTopLeftText,
    searchBy,
    updateValue,
    viewAutoCompleteOptions,
    justifyAEnd,
    justifyAStart,
    onAutocompleteSelect,
    onAutocompleteSearch,
    autoCompleteIntValue,
    filterOption,
    bookingStatus,
    bookingStatusFilter,
    bookingStatusFlag,
    cancelledbookingCheckBox,
    setCancelledBookingCheckBox,
    bookingStatusTitle,
    setDateTo,
    dateTo,
    dateFrom,
    setDateFrom,
    bulkUpdate,
    modalPopuphandel,
    propertyAdminOptions,
    propertyAdmins,
    setDrawer,
    additionalFilters,
    resetLandloard
  } = props;



  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [tempValue, setTempValue]: any = React.useState();
  useEffect(() => {
    const views = document.querySelector("#display-views") as HTMLInputElement;
    const gridselect = document.querySelector(".grid");
    const listselect = document.querySelector(".list");
    const grid = document.querySelector(".prop-card-grid");
    listselect.addEventListener("click", () => {
      grid.classList.add("prop-list-grid");
      gridselect.classList.remove("grid-selected");
      listselect.classList.add("grid-selected");
    });
    gridselect.addEventListener("click", () => {
      grid.classList.remove("prop-list-grid");
      gridselect.classList.add("grid-selected");
      listselect.classList.remove("grid-selected");
    });

  });


  useEffect(() => {
    if (updateValue?.length > 0) {
      updateValue?.map((e) => {
        e?.label && setValue(e?.label, e?.value);
      });
    } else if (updateValue?.label)
      setValue(updateValue?.label, updateValue?.value);
  }, [updateValue]);

  useEffect(() => {

    if (searchOptions?.length) {
      let index = searchOptions.findIndex((x) => x.default === true);
      setTempValue(searchOptions[index]);
    }
  }, [searchOptions]);

  const searchDropdown =
    searchOptions?.length > 0 ? (
      <Select
        className={(propertyAdminOptions && (tempValue === "propertyAdmin" || tempValue?.value === "propertyAdmin")) && "custom-select"}
        value={tempValue}
        defaultValue={tempValue}
        labelInValue
        placeholder={`Select ${searchName ? searchName : ""}`}
        style={{
          minWidth: 100,
          backgroundColor: "#f0f2f5", // Light gray background
          borderRadius: 6, // Optional: for rounded corners
        }}
        onChange={(e: any) => {
          if (e && e?.value) {
            setTempValue(e?.value);
            searchBy(e)
          }
        }}
        size="large"
        dropdownMatchSelectWidth={false}
        options={searchOptions}
      />
    ) : (
      false
    );

  // Handler for when "to" date changes
  const handleToDateChange = (date) => {
    if (dateFrom && date && dateFrom.isAfter(date)) {
      // Show alert if "to" date is not greater than "from" date
      message.error('The "to" date must be greater than the "from" date.');
      setDateTo(null)
    } else {
      date === null ? setDateTo(null) : setDateTo(date)
    }
  };

  const dataFrom = (date) => {

    if (date && dateTo && date.isAfter(dateTo)) {
      // Show alert if "to" date is not greater than "from" date
      message.error('The "to" date must be greater than the "from" date.');
      setDateFrom(null)
    } else {
      setDateFrom(date);
    }

  }

  const handleInputChange = (e) => {
    search(e);
  };

  return (
    <>
      <Row gutter={[24, 24]} className="bc">
        <Col flex="auto">
          <Space size={8} className="h-100">

            <Title
              level={2}
              className={`custom-title32 ${total ? "borderRight" : ""}`}
            >
              {heading}
            </Title>

            {total && (
              <Title level={5} className="mb-0 total-title">
                {total} Total
              </Title>
            )}

            {headTopLeftText && (
              <Title
                level={5}
                className={`mb-0 total-title ${headTopLeftText ? "borderRight" : ""
                  }`}
              >
                {headTopLeftText}
              </Title>
            )}
          </Space>
        </Col>
        {importAction && (
          <Col>
            <Button
              size="large"
              icon={<DownloadOutlined />}
              onClick={importAction}
            >
              Import
            </Button>
          </Col>
        )}
        {filterAction && (
          <Col>
            <Button size="large" icon={<FilterOutlined />} onClick={filterAction}>
              Filters
            </Button>
          </Col>
        )}
        {downloadAction && (
          <Col>
            <Button
              size="large"
              type="primary"
              className="property_card_btn"
              onClick={() => downloadAction()}
            >
              Export
            </Button>
          </Col>
        )}

        {
          bulkUpdate &&
          <Col>
            <Button
              size="large"
              type="primary"
              className="property_card_btn"
              onClick={() => modalPopuphandel(1)}
            >
              Bulk Update
            </Button>
          </Col>
        }
        {addNewAction && (
          <Col>
            <Button
              size="large"
              type="primary"
              className="property_card_btn"
              onClick={addNewAction}
            >
              Add New {props.addText && props.addText} <PlusOutlined />
            </Button>
          </Col>
        )}

        {dropNewAction && (
          <Col>
            <Dropdown
              menu={{ items: dropItems, onClick: dropNewAction }}
              trigger={dropTrigger}
            >
              <Button
                size="large"
                type="primary"
                className="property_card_btn"
                onClick={(e) => e.preventDefault()}
              >
                Add New {props.addText && props.addText} <PlusOutlined />
              </Button>
            </Dropdown>
          </Col>
        )}
        {addMoreButton && (
          <Col>
            <Button
              size="large"
              type="primary"
              className="property_card_btn"
              disabled={props?.isDisabled}
              onClick={addMoreButton}
            >
              {props.addMoreText && props.addMoreText}
            </Button>
          </Col>
        )}
        {exportAction && (
          <Col>
            <Button
              type="primary"
              className="property_card_btn"
              icon={<ExportOutlined />}
              onClick={addMoreButton}
            >
              Export
            </Button>
          </Col>
        )}

        <Col
          span={24}
          style={
            (search || viewOption || viewRSelectOption || dateAction) && {
              height: "80px",
            }
          }
        >
          <Form
            className="ag-filter-bar org_form bf-main"
            //  className=""
            style={
              headReverse
                ? { flexDirection: "row-reverse", justifyContent: "start" }
                : { alignItems: "start" }
            }
            name="property_filter"
            layout="vertical"
          >

            {search && (
              <Col
                xs={12}
                lg={15}
                xl={14}
                style={headReverse ? {} : { float: "left" }}
              >
                <Form.Item
                  className="search"
                  label={searchDropdown ? "Search by:" : " "}
                >
                  {
                    (tempValue !== "propertyAdmin" && tempValue?.value !== "propertyAdmin") &&
                    <Search
                      key={defaultSearch + tempValue}
                      addonBefore={searchDropdown}
                      className="org_search"
                      style={{ ...(viewRSelectOption ? { marginTop: 7 } : {}) }}
                      placeholder={searchLabel ?? "Search..."} //
                      enterButton
                      defaultValue={defaultSearch ?? ""}
                      bordered={false}
                      size="large"
                      allowClear
                      onSearch={search}
                    />

                  }


                  {
                    (
                      propertyAdmins?.length > 0 && propertyAdminOptions &&
                      (tempValue === "propertyAdmin" || tempValue?.value === "propertyAdmin")

                    ) &&


                    <Input.Group compact>
                      {searchDropdown}
                      <Select
                        key={defaultSearch + tempValue}
                        style={{
                          minWidth: '73%',
                          background: '#fff',
                          // borderRadius:'5px',
                          width: "100%",
                          ...(viewRSelectOption ? { marginTop: 7 } : {}),
                        }}
                        placeholder={"Select an option..."}
                        size="large"
                        allowClear
                        defaultValue={defaultSearch ? defaultSearch : "All"}

                        bordered={false}
                        onChange={(value) => handleInputChange(value)}
                        options={propertyAdmins}
                        showSearch
                      />
                      <Button
                        disabled
                        type="primary"
                        icon={<SearchOutlined />}
                        size="large"
                        style={{
                          borderTopRightRadius: "6px",
                          borderBottomRightRadius: "6px"
                        }}
                      />

                    </Input.Group>

                  }

                </Form.Item>
              </Col>
            )}



            {

              <Col
                xs={12}
                lg={12}
                xl={(screenSize === 'xl' && setDateTo) ? 19 : 6}
                className={justifiedItems ? "org_form" : "wrapped-items"}
                // style={
                //   justifyEnd
                //     ? { justifyContent: "end" }
                //     : justifyStart
                //       ? { justifyContent: "start" }
                //       : {}
                // } 
                style={{
                  ...(justifyEnd ? { justifyContent: "end" } : justifyStart ? { justifyContent: "start" } : {}),
                  ...(bottom ? { position: "absolute", bottom: 0 } : {}),
                  ...(right ? { position: "absolute", right: 15 } : {}),
                }}
              >
                {viewOptionLabel && (
                  <div className="viewOptiontitle">
                    <i>
                      {viewOptionIcon ?? ""} {viewOptionLabel ?? ""}
                    </i>
                  </div>
                )}

                {(viewOption && viewOption.length > 0) && viewOption.map((item, index) => (
                  <Controller
                    key={item?.name}
                    name={item?.name}
                    control={control}
                    defaultValue={item?.initialValue}
                    render={({ field: { onChange, value, ref } }) => (
                      <Form.Item
                        style={justifyEnd ? { marginLeft: "9px" } : {}}

                        label={`${item?.label} (${item?.options?.length > 0 ? item?.options?.length : 0
                          })`}
                        initialValue={item?.initialValue}
                      >
                        <Select
                          disabled={item?.unitLoading}
                          value={props.disabled ? "" : value}
                          ref={ref}
                          onChange={(e) => {
                            onChange(e);
                            handleView(e, item?.name ?? "");
                          }}
                          showSearch={item?.search === true ? true : false}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          labelInValue
                          placeholder={`Select ${item?.name}`}
                          size="large"
                          options={item?.options}
                        />

                      </Form.Item>
                    )}
                  />
                ))}



                {(viewOptionAction && (viewOption && viewOption.length > 0)) && (
                  <Button
                    size="large"
                    type="primary"
                    style={{ marginLeft: "9px", padding: "5px 14px !important" }}
                    className="property_card_btn"
                    onClick={viewOptionAction}
                  >

                    <PlusOutlined />
                  </Button>
                )}
                {
                  (screenSize === 'xl' && setDateTo) &&
                  <>

                    <Form.Item
                      label={`Date From`}
                    >
                      <DatePicker

                        placeholder="Date From"
                        disabledDate={disableFutureDates}
                        value={dateFrom}

                        onChange={(e) => {

                          dataFrom(e);

                        }}


                      />
                    </Form.Item>
                    <Form.Item
                      label={`Date To`}
                    >

                      <DatePicker

                        disabledDate={disableFutureDates}
                        placeholder="Date To"
                        value={dateTo}
                        onChange={(e) => {
                          handleToDateChange(e);
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button size="small" className="ef-approve-btn" style={{ marginTop: '24px' }} onClick={() => setDrawer(true)}>More Filters</Button>
                    </Form.Item>
                  </>
                }


              </Col>

            }

            {viewRSelectOption && viewRSelectOption.length && (
              <Col
                xs={24}
                lg={14}
                xl={8}
                className={justifiedItems ? "org_form" : "wrapped-items"}
                style={
                  justifyEnd
                    ? { justifyContent: "end" }
                    : justifyStart
                      ? { justifyContent: "start" }
                      : {}
                }
              >
                {viewRSelectOption.map((item) => (
                  <div key={item?.name + Math.random()} style={{ marginRight: 5 }}>
                    <ReactSelectField
                      isRequired={false}
                      style={{
                        borderRadius: 8,
                        borderColor: "#ffffff",
                        minHeight: 40,
                        minWidth: 200,
                      }}
                      fieldname={item?.name}
                      label={item?.name}
                      control={control}
                      iProps={{ placeholder: item.placeholder }}
                      nav={true}
                      onChange={onChange}
                      initialValue={initialValue}
                      selectOption={item?.options?.length > 0 && item?.options}
                    />
                  </div>
                ))}
              </Col>
            )}

            {(viewAutoCompleteOptions && viewAutoCompleteOptions.length && !propertyAdminOptions) &&
              (
                <Col
                  xs={24}
                  lg={8}
                  xl={8}
                  className={justifiedItems ? "org_form" : "wrapped-items"}
                  style={
                    (justifyAEnd && screenSize == 'lg')
                      ? { justifyContent: "end" }
                      : justifyAStart
                        ? { justifyContent: "start" }
                        : {}
                  }
                >
                  {viewAutoCompleteOptions.map((item) => (
                    <div style={{ marginLeft: 5 }} key={`${item.name}`}>
                      <InputAutocompleteField
                        // value={resetLandloard}
                        // key={`${item.name}`}
                        isRequired={false}
                        initValue={item.defaultValue}
                        onSelect={onAutocompleteSelect}
                        onSearch={onAutocompleteSearch}
                        filterOption={filterOption}
                        onChange={onChange}
                        options={item.options}
                        fieldname={item?.name}
                        label={item?.label}
                        control={control}
                        style={{ width: 300 }}
                        setValue={setValue}
                        iProps={{ placeholder: item.placeholder, style: { marginTop: 4 } }}
                        valueGot={resetLandloard?.includes("reset") && ""}
                      />
                    </div>
                  ))}
                </Col>
              )}
            {dateAction && (
              <Col
                xs={24}
                lg={16}
                xl={16}
                className={justifiedItems ? "org_form" : "wrapped-items"}
              >
                <div style={{ paddingLeft: "14px" }}>Select Date: </div>

                <Form.Item name="Date" label="Renewal Date" initialValue="">
                  <DateField
                    fieldname="dateSearch"
                    control={control}
                    onChange={(e) => dateAction(e)}
                    initValue={initialDate ?? ""}
                    iProps={{
                      placeholder: "DD-MM-YYYY",
                      format: "DD-MM-YYYY",

                    }}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={3} className="viewby">
              <div id="display-views">
                <p>View By</p>
                <div className="grid grid-selected">
                  <img src={grid} />
                </div>
                <div className="list">
                  <img src={list} />
                </div>
              </div>
            </Col>
            {bookingStatus && (
              <Col span={4}>
                <BookingStatusFilter
                  cancelledBookingCheck={cancelledBookingCheck}
                  bookingStatusTitle={bookingStatusTitle}
                  bookingStatuses={bookingStatuses}
                  bookingStatusFilter={bookingStatusFilter}
                  bookingStatusValue={bookingStatusFlag}
                  setCancelledBookingCheckBox={setCancelledBookingCheckBox}
                  cancelledbookingCheckBox={cancelledbookingCheckBox}
                />
              </Col>
            )}
          </Form>
        </Col>


        {(!props.noborder && !setDateTo) && <Divider className="mt-0 ag-border-cold9" />}
      </Row>

      {
        (screenSize == 'lg' && setDateTo) &&
        <Row gutter={[10, 24]}>
          {
            <Col>

              <DatePicker
                style={{ borderRadius: '6px', width: '150px' }}
                size={'large'}
                disabledDate={disableFutureDates}
                placeholder="Date From"
                value={dateFrom}
                onChange={(e) => {
                  dataFrom(e);
                }}


              />
            </Col>}
          <Col>

            <DatePicker
              style={{ borderRadius: '6px', width: '150px' }}
              size={'large'}
              placeholder="Date To"
              disabledDate={disableFutureDates}

              value={dateTo}
              onChange={(e) => {
                handleToDateChange(e);
              }}
            />

          </Col>

          {
            (screenSize === 'lg' && setDateTo && additionalFilters)
            &&
            <Col>
              <Form.Item style={{ marginTop: '5px' }}>
                <Button className="ef-approve-btn" size="small" style={{ zIndex: 9 }} onClick={() => setDrawer(true)}>More Filters</Button>
              </Form.Item>
            </Col>

          }
          {(screenSize === 'lg' && setDateTo) && <Divider className="mt-0 ag-border-cold9" />}
        </Row>
      }
    </>
  );
});


