import React, { ReactElement } from "react";

// others
import { Button } from "antd";

interface IProps {
  type?: any;
  title?:any;
  label?: any;
  onClick?: any;
  style?: any;
  className?: string;
  disabled?: boolean;
  icon?: ReactElement;
  myClassSet?:boolean
}

const index = (props: IProps) => {
  return (
    <Button
      title={props?.title}
      icon={props.icon}
      style={props.style}
      htmlType={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      className={ !props?.myClassSet ? `bf-button ${props.className}` : `${props.className}` }
    >
      {props.label}
    </Button>
  );
};

export default index;
